import qs from 'query-string';
import { NAVIGATION_COMPONENT_TYPE } from 'constant/NavigationConstant';
import { PATH_CONSTANT } from 'constant/PathConstant';
import { MyViewWorkFlowItem, QuickAccessItem } from 'types/api/Tenant/ConfigurationTypes';
import { DISPLAY_TABLE_QUERY_KEY } from 'services/v1/Tenant/AROKMS/DisplayTableService';
import { SUBJECT_QUERY_KEY } from 'services/v1/SystemTenant/AROKMS/SubjectService';
import { EVENT_TABLE_QUERY_KEY } from 'services/v1/Tenant/AROEvent/EventTableService';
import { KTREE_TENANT_DATA_QUERY } from 'services/v1/Tenant/AROKMS/KTreeDataService';
import { HOME_DASHBOARD_KEY } from 'services/v1/Common/NavBarMenuService';

export enum WorkFlowDrillNavigationDirection {
  UP = 'UP',
  DOWN = 'DOWN',
}

export const getWorkFlowDrillView = (
  currentMyViewConfig: QuickAccessItem | null,
  currentWorkFlowId: string | null,
  direction: WorkFlowDrillNavigationDirection
): MyViewWorkFlowItem | null => {
  console.log({
    currentMyViewConfig,
    currentWorkFlowId,
  });
  if (currentMyViewConfig && !currentWorkFlowId) {
    return currentMyViewConfig.drillDownWorkflows[0];
  }

  if (currentMyViewConfig && currentWorkFlowId) {
    if (direction === WorkFlowDrillNavigationDirection.DOWN) {
      const currentIndex = currentMyViewConfig.drillDownWorkflows.findIndex((item) => item.id === currentWorkFlowId);

      if (currentIndex === -1) return null;
      return currentMyViewConfig.drillDownWorkflows[currentIndex + 1];
    }
    if (direction === WorkFlowDrillNavigationDirection.UP) {
      const currentIndex = currentMyViewConfig.drillDownWorkflows.findIndex((item) => item.id === currentWorkFlowId);
      if (currentIndex === -1) return null;
      return currentMyViewConfig.drillDownWorkflows[currentIndex - 1];
    }
  }
  return null;
};

export const getNextDrillDownURL = (
  currentMyViewConfig: QuickAccessItem | null,
  nextDrillDownWorkFlow: MyViewWorkFlowItem | null,
  additionalParams: { [key: string]: string | number | boolean | null | undefined | string[] } | undefined = {}
): string | null => {
  if (!currentMyViewConfig || !nextDrillDownWorkFlow) return null;

  switch (nextDrillDownWorkFlow?.componentType) {
    case NAVIGATION_COMPONENT_TYPE.CUBE:
      const cubeURL = `${PATH_CONSTANT.TENANT.CUBE.CUBE_DIMENSIONAL_VIEW.replace(
        ':cubeId',
        nextDrillDownWorkFlow.componentId
      )}?${qs.stringify({
        myViewId: currentMyViewConfig.viewId,
        workflowId: nextDrillDownWorkFlow.id,
        ...additionalParams,
      })}`;
      return cubeURL;
    case NAVIGATION_COMPONENT_TYPE.INSIGHT_VIEW:
      const insightViewConfig = currentMyViewConfig;
      const insightViewURL = `${PATH_CONSTANT.TENANT.CUBE.INSIGHT.replace(':cubeId', insightViewConfig.cubeId).replace(
        ':insightId',
        nextDrillDownWorkFlow.componentId
      )}?${qs.stringify({
        memberId: insightViewConfig.memberId,
        defaultXDimension: insightViewConfig.defaultDimensionFilter?.defaultXDimension,
        defaultYDimension: insightViewConfig.defaultDimensionFilter?.defaultYDimension,
        defaultFilterMember: insightViewConfig.defaultDimensionFilter?.defaultFilterMember,
        myViewId: insightViewConfig.viewId,
        workFlowId: nextDrillDownWorkFlow?.id || insightViewConfig.drillDownWorkflows[0]?.id,
        ...additionalParams,
      })}`;
      return insightViewURL;

    case NAVIGATION_COMPONENT_TYPE.CHART:
      const chartViewConfig = currentMyViewConfig;
      const chartViewURL = `${PATH_CONSTANT.TENANT.CHART.CHART_VIEW.replace(
        ':chartViewId',
        nextDrillDownWorkFlow.componentId
      )}?${qs.stringify({
        memberId: chartViewConfig.memberId,
        defaultFilterMember: chartViewConfig.defaultDimensionFilter?.defaultFilterMember,
        myViewId: chartViewConfig.viewId,
        workFlowId: nextDrillDownWorkFlow?.id || chartViewConfig.drillDownWorkflows[0]?.id,
        ...additionalParams,
      })}`;
      return chartViewURL;
    default:
      return null;
  }
};

export function getCurrentMyViewIndex(myViewItems: QuickAccessItem[], currentMyViewId: string) {
  const currentMyViewIndex = myViewItems.findIndex((item) => item.viewId === currentMyViewId);
  return currentMyViewIndex;
}

export function getNextMyViewItem(myViewItems: QuickAccessItem[], currentMyViewIndex: number) {
  if (currentMyViewIndex > -1) {
    if (currentMyViewIndex < myViewItems.length - 1) {
      return myViewItems[currentMyViewIndex + 1];
    }
  }
  return null;
}

export function getPreviousMyViewItem(myViewItems: QuickAccessItem[], currentMyViewIndex: number) {
  if (currentMyViewIndex > -1) {
    if (currentMyViewIndex > 0) {
      return myViewItems[currentMyViewIndex - 1];
    }
  }
  return null;
}

function prefetchData(
  viewItem: QuickAccessItem,
  prefetchMyViewDetails: (viewId: string) => void,
  prefetchDataInputTable: (key: any[], filter: any) => void,
  prefetchSubjectDetails: (key: any[], value: string) => void,
  prefetchEventTableData: (key: any[], filter: any) => void,
  prefetchKTreeData: (key: any[], filter: any) => void,
  prefetchBreadcumbItems: (
    key: any[],
    filter: {
      componentId?: string;
      componentType?: NAVIGATION_COMPONENT_TYPE;
      subjectId?: string;
      myViewId?: string | null;
    }
  ) => void
) {
  if (!viewItem) return;

  prefetchMyViewDetails(viewItem.viewId);
  const breadCumbItemsKey = [
    HOME_DASHBOARD_KEY.BREADCRUMBS,
    viewItem.value,
    viewItem.componentType,
    viewItem.componentType === NAVIGATION_COMPONENT_TYPE.EVENT ? viewItem.subjectId : null,
    viewItem.viewId,
  ];
  prefetchBreadcumbItems(breadCumbItemsKey, {
    componentId: viewItem.value,
    myViewId: viewItem.viewId,
    componentType: viewItem.componentType,
    subjectId: viewItem.componentType === NAVIGATION_COMPONENT_TYPE.EVENT ? viewItem.subjectId : undefined,
  });

  if (viewItem.componentType === NAVIGATION_COMPONENT_TYPE.SUBJECT) {
    const filter = { subjectId: viewItem.value, procedureId: undefined };
    const manualKey = [DISPLAY_TABLE_QUERY_KEY.DATA_LIST, { filter }];
    prefetchDataInputTable(manualKey, filter);

    const subjectDetailsManualKey = [SUBJECT_QUERY_KEY.SUBJECT_DETAILS, viewItem.value];
    prefetchSubjectDetails(subjectDetailsManualKey, viewItem.value);
  }

  if (viewItem.componentType === NAVIGATION_COMPONENT_TYPE.EVENT) {
    const filter = { subjectId: viewItem.value };
    const manualKey = [EVENT_TABLE_QUERY_KEY.DATA_LIST, { filter }];
    prefetchEventTableData(manualKey, filter);

    const subjectDetailsManualKey = [SUBJECT_QUERY_KEY.SUBJECT_DETAILS, viewItem.value];
    prefetchSubjectDetails(subjectDetailsManualKey, viewItem.value);
  }

  if (viewItem.componentType === NAVIGATION_COMPONENT_TYPE.K_TREE) {
    const kTreeId = viewItem.value;
    const manualKey = [KTREE_TENANT_DATA_QUERY.KTREE_DATA, kTreeId];
    prefetchKTreeData(manualKey, kTreeId);
  }
}

export function handlePrefetch(
  viewItem: QuickAccessItem,
  nextViewItem: QuickAccessItem | null,
  prefetchMyViewDetails: (viewId: string) => void,
  prefetchDataInputTable: (key: any[], filter: any) => void,
  prefetchSubjectDetails: (key: any[], value: string) => void,
  prefetchEventTableData: (key: any[], filter: any) => void,
  prefetchKTreeData: (key: any[], filter: any) => void,
  prefetchBreadcumbItems: (
    key: any[],
    filter: {
      componentId?: string;
      componentType?: NAVIGATION_COMPONENT_TYPE;
      subjectId?: string;
      myViewId?: string | null;
    }
  ) => void
) {
  prefetchData(
    viewItem,
    prefetchMyViewDetails,
    prefetchDataInputTable,
    prefetchSubjectDetails,
    prefetchEventTableData,
    prefetchKTreeData,
    prefetchBreadcumbItems
  );

  if (nextViewItem) {
    prefetchData(
      nextViewItem,
      prefetchMyViewDetails,
      prefetchDataInputTable,
      prefetchSubjectDetails,
      prefetchEventTableData,
      prefetchKTreeData,
      prefetchBreadcumbItems
    );
  }
}
