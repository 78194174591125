import React, { useEffect, useMemo, useState } from 'react';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import ButtonLoading from '@mui/lab/LoadingButton';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import InputAdornment from '@mui/material/InputAdornment';
import { ReactComponent as CubeAccrossIcon } from 'assets/icons/icon-cube-across.svg';
import { ReactComponent as CubeDown } from 'assets/icons/icon-cube-down.svg';
import { useGetAdditionalWorkFlowsDropdown } from 'services/v1/Tenant/ConfigurationService';
import { PeopleMyViewItemConfig, RequestSaveMyViewAdditionalConfig } from 'types/api/Tenant/ConfigurationTypes';
import { Autocomplete, Box, TextField } from '@mui/material';
import { AutoCompleteItem } from 'types/api/Common/AutoCompleteTypes';
import { flattenTreeData, renderOptionsKtree, getListBoxPropsAutoScrolItem } from 'utils/Object';
import {
  INSIGHT_VIEW_MEASUREMENT_TYPE,
  INSIGHT_VIEW_MEASURE_TYPE_DROPDOWN_OPTIONS,
  getInsightViewDimensionFilterLabel,
  getInsightViewMeasureTypeDropdowns,
} from 'constant/ViewConstant';
import { useGetCubeDimensionalViewFilterMember } from 'services/v1/Tenant/AROCube/CubeDimensionalViewService';
import { DIMENSION_CATEGORY } from 'types/api/Tenant/AROCube/CubeDataDimensionalViewTypes';
import { useGetKtreeData } from 'services/v1/Tenant/AROKMS/KTreeDataService';
import { useGetChartViewPanelOptions } from 'services/v1/Tenant/AROChart/ChartViewService';
import { NAVIGATION_COMPONENT_TYPE } from 'constant/NavigationConstant';

const ModalContentStyle = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 550,
  bgcolor: 'background.paper',
  minHeight: 400,
  maxHeight: 800,
  overflow: 'auto',
  borderRadius: 1,
  py: 2,
};

const autoCompleteStyle = {
  width: 230,
  '& .MuiAutocomplete-popper': {
    backgroundColor: 'red !imoprtant',
    fontSize: '10px',
  },
};

const closeIconStyle = { color: '#98A2AE', cursor: 'pointer' };

export interface ModalMyViewAdditionalConfigProps {
  visible?: boolean;
  onClose?: () => void;
  onSubmit: (data: RequestSaveMyViewAdditionalConfig) => void;
  selectedView: PeopleMyViewItemConfig | null;
  defaultXDimensionTypeId: string | null;
  defaultYDimensionTypeId: string | null;
  defaultFilterMemberId: string | null;
}

interface DefaultFilterDimensionValue {
  defaultXDimensionType: AutoCompleteItem | null;
  defaultYDimensionType: AutoCompleteItem | null;
  defaultFilterMember: AutoCompleteItem | null;
  member: AutoCompleteItem | null;
  additionalWorkFlows: AutoCompleteItem | null;
  defaultMeasurement: AutoCompleteItem | null;
  currentPeriodOffset: number | null;
}

const renderOption = (props: React.HTMLAttributes<HTMLLIElement>, option: AutoCompleteItem) => (
  <Box component='li' sx={{ '& > span': { fontSize: '14px', mr: 1, flexShrink: 0 } }} {...props}>
    <span>{option.label}</span>
  </Box>
);
export function ModalMyViewAdditionalConfig(props: Readonly<ModalMyViewAdditionalConfigProps>) {
  const {
    onClose,
    onSubmit,
    visible = false,
    selectedView,
    defaultFilterMemberId,
    defaultXDimensionTypeId,
    defaultYDimensionTypeId,
  } = props;

  const allDimensionOptions = useMemo(() => {
    return getInsightViewMeasureTypeDropdowns(selectedView?.ktreeName);
  }, [selectedView?.ktreeName]);

  const { data: chartViewPanel, isLoading: isFetchingMeasurement } = useGetChartViewPanelOptions(
    selectedView?.componentType === NAVIGATION_COMPONENT_TYPE.CHART ? selectedView?.componentId : undefined
  );

  const initialDefaultYDimensionType = useMemo(() => {
    return allDimensionOptions.filter((item) => item.value === INSIGHT_VIEW_MEASUREMENT_TYPE.INSIGHT_COLUMNS_MEMBER)[0];
  }, [allDimensionOptions]);

  const initialDefaultXDimensionType = useMemo(() => {
    return allDimensionOptions.filter((item) => item.value === INSIGHT_VIEW_MEASUREMENT_TYPE.MEASUREMENT_MEMBER)[0];
  }, [allDimensionOptions]);

  const initialDefaultFilterValue = useMemo(() => {
    return {
      defaultFilterMember: null,
      defaultYDimensionType: initialDefaultYDimensionType,
      defaultXDimensionType: initialDefaultXDimensionType,
      member: null,
      additionalWorkFlows: null,
      defaultMeasurement: null,
      currentPeriodOffset: 0,
    };
  }, [initialDefaultYDimensionType, initialDefaultXDimensionType]);

  const [optionValue, setOptionValue] = useState<DefaultFilterDimensionValue>({
    defaultFilterMember: null,
    defaultYDimensionType: null,
    defaultXDimensionType: null,
    member: null,
    additionalWorkFlows: null,
    defaultMeasurement: null,
    currentPeriodOffset: 0,
  });

  const { data: additionalWorkFlowsDropdown } = useGetAdditionalWorkFlowsDropdown();

  const { data: dimensionMemberDropdown, isLoading: isFetchingDimensionMember } = useGetCubeDimensionalViewFilterMember(
    'MAIN_MEMBER',
    selectedView?.cubeDefinitionId,
    optionValue.defaultXDimensionType?.value?.toString(),
    optionValue.defaultYDimensionType?.value?.toString(),
    DIMENSION_CATEGORY.CUBE_INSIGHT_VIEW,
    selectedView?.componentId
  );
  const { data: memberDropdown, isLoading: isFetchingMember } = useGetKtreeData(
    selectedView?.ktreeDefinitionId?.toString()
  );

  const handleOnCloseModal = () => {
    setOptionValue(initialDefaultFilterValue);
    onClose?.();
  };

  const handleOnCancel = () => {
    handleOnCloseModal();
  };

  const handleOnSave = () => {
    if (selectedView) {
      const payload = {
        myViewId: selectedView.id,
        defaultXDimension: optionValue.defaultXDimensionType?.value?.toString() || null,
        defaultYDimension: optionValue.defaultYDimensionType?.value?.toString() || null,
        defaultFilterMember: optionValue.defaultFilterMember?.value?.toString() || null,
        memberId: optionValue.member?.value?.toString() || null,
        memberName: optionValue.member?.label || null,
        additionalWorkFlows: optionValue.additionalWorkFlows ? [optionValue.additionalWorkFlows.value.toString()] : [],
        defaultMeasurement: optionValue.defaultMeasurement?.value?.toString() || null,
        currentPeriodOffset: optionValue.currentPeriodOffset || 0,
      };

      onSubmit(payload);
      handleOnCloseModal();
      setOptionValue(initialDefaultFilterValue);
    }
  };

  const dimensionMemberOptions = useMemo(() => {
    if (dimensionMemberDropdown) {
      return flattenTreeData(dimensionMemberDropdown);
    }
    return [];
  }, [dimensionMemberDropdown]);

  const memberOptions = useMemo(() => {
    if (memberDropdown?.data) {
      return flattenTreeData(memberDropdown?.data || []);
    }
    return [];
  }, [memberDropdown?.data]);

  const xDimensionOptions = useMemo(() => {
    return INSIGHT_VIEW_MEASURE_TYPE_DROPDOWN_OPTIONS.filter(
      (item) => item.value !== optionValue.defaultYDimensionType?.value
    ).map((item) => {
      if (item.value === INSIGHT_VIEW_MEASUREMENT_TYPE.MAIN_MEMBER && selectedView?.ktreeName) {
        return { ...item, label: selectedView.ktreeName };
      }
      return item;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionValue.defaultYDimensionType, optionValue.defaultXDimensionType, selectedView]);

  const yDimensionOptions = useMemo(() => {
    return INSIGHT_VIEW_MEASURE_TYPE_DROPDOWN_OPTIONS.filter(
      (item) => item.value !== optionValue.defaultXDimensionType?.value
    ).map((item) => {
      if (item.value === INSIGHT_VIEW_MEASUREMENT_TYPE.MAIN_MEMBER && selectedView?.ktreeName) {
        return { ...item, label: selectedView.ktreeName };
      }
      return item;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionValue.defaultXDimensionType, optionValue.defaultYDimensionType, selectedView]);

  const showDefaultDimensionMemberFilter = useMemo(() => {
    const excludeRule = new Set([
      INSIGHT_VIEW_MEASUREMENT_TYPE.INSIGHT_COLUMNS_MEMBER,
      INSIGHT_VIEW_MEASUREMENT_TYPE.MEASUREMENT_MEMBER,
    ]);

    // As long as the selected rule is not in the excludeRule set, we will show the default dimension member filter
    return (
      !excludeRule.has(optionValue.defaultXDimensionType?.value as string) ||
      !excludeRule.has(optionValue.defaultYDimensionType?.value as string)
    );
  }, [optionValue.defaultXDimensionType, optionValue.defaultYDimensionType]);

  const lowestDimensionMemberLevel = useMemo(() => {
    // @ts-ignore
    return dimensionMemberOptions.reduce((acc, item) => {
      if (item.level > acc) {
        return item.level;
      } else {
        return acc;
      }
    }, 0);
  }, [dimensionMemberOptions]);

  const lowestMemberLevel = useMemo(() => {
    // @ts-ignore
    return memberOptions.reduce((acc, item) => {
      if (item.level > acc) {
        return item.level;
      } else {
        return acc;
      }
    }, 0);
  }, [memberOptions]);

  const dimensionFilterLabel = useMemo(() => {
    return getInsightViewDimensionFilterLabel(
      selectedView?.ktreeName,
      undefined,
      optionValue.defaultXDimensionType?.value.toString(),
      optionValue.defaultYDimensionType?.value.toString()
    );
  }, [optionValue.defaultXDimensionType, optionValue.defaultYDimensionType, selectedView?.ktreeName]);

  useEffect(() => {
    setOptionValue(initialDefaultFilterValue);
  }, [initialDefaultFilterValue]);

  useEffect(() => {
    if (dimensionMemberOptions.length > 0) {
      setOptionValue((prev) => ({ ...prev, defaultFilterMember: dimensionMemberOptions[0] }));
    }
  }, [dimensionMemberOptions]);

  useEffect(() => {
    if (memberOptions) {
      const selectedMember = memberOptions.find(
        (item: AutoCompleteItem) => item.value === selectedView?.componentMemberId
      );
      if (selectedMember) {
        setOptionValue((prev) => ({ ...prev, member: selectedMember }));
      }
    }
  }, [memberOptions, selectedView?.componentMemberId, visible]);

  useEffect(() => {
    let selectedXValue: AutoCompleteItem | null = null;
    let selectedYValue: AutoCompleteItem | null = null;
    let selectedFilterValue: AutoCompleteItem | null = null;
    if (defaultXDimensionTypeId) {
      selectedXValue = allDimensionOptions.find((item) => item.value === defaultXDimensionTypeId) || null;
    }
    if (defaultYDimensionTypeId) {
      selectedYValue = allDimensionOptions.find((item) => item.value === defaultYDimensionTypeId) || null;
    }

    if (selectedXValue && selectedYValue) {
      setOptionValue((prevState) => ({
        ...prevState,
        defaultXDimensionType: selectedXValue,
        defaultYDimensionType: selectedYValue,
        defaultFilterMember: selectedFilterValue,
      }));
    }
  }, [defaultYDimensionTypeId, defaultXDimensionTypeId, allDimensionOptions, defaultFilterMemberId]);

  useEffect(() => {
    if (defaultFilterMemberId) {
      const selectedFilterValue =
        dimensionMemberOptions.find((item: AutoCompleteItem) => item.value === defaultFilterMemberId) || null;
      if (selectedFilterValue) {
        setOptionValue((prevState) => ({
          ...prevState,
          defaultFilterMember: selectedFilterValue,
        }));
      }
    }
  }, [dimensionMemberOptions, defaultFilterMemberId]);

  useEffect(() => {
    if (selectedView?.additionalWorkFlows) {
      const additionalWorkFlowCube = selectedView.additionalWorkFlows?.[2];
      if (additionalWorkFlowCube) {
        setOptionValue((prevState) => ({
          ...prevState,
          additionalWorkFlows: {
            label: additionalWorkFlowCube.componentName,
            value: additionalWorkFlowCube.componentId.toString(),
          },
        }));
      } else {
        setOptionValue((prevState) => ({
          ...prevState,
          additionalWorkFlows: null,
        }));
      }
    }
  }, [selectedView?.additionalWorkFlows, visible]);

  useEffect(() => {
    if (selectedView?.defaultMeasurement) {
      const selectedMeasurement = chartViewPanel?.data.measureOptions.find(
        (item) => item.value === selectedView.defaultMeasurement
      );
      setOptionValue((prevState) => ({
        ...prevState,
        defaultMeasurement: selectedMeasurement || null,
        currentPeriodOffset: selectedView.currentPeriodOffset,
      }));
    }
  }, [selectedView?.currentPeriodOffset, selectedView?.defaultMeasurement, chartViewPanel?.data.measureOptions]);

  const isValidForm = optionValue.defaultXDimensionType !== null && optionValue.defaultYDimensionType !== null;

  return (
    <div>
      <Modal open={visible} onClose={handleOnCloseModal}>
        <Stack direction='column' sx={ModalContentStyle} px={2} justifyContent='space-between'>
          <Stack>
            <Stack>
              <Stack justifyContent='space-between' direction='row' alignItems='center'>
                <Typography variant='body1' component='h2' fontWeight='bold' sx={{ color: '#3B4797' }}>
                  {`Custom Config for ${selectedView?.componentName}`}
                </Typography>
                <IconButton onClick={handleOnCloseModal} component='label'>
                  <HighlightOffRoundedIcon sx={closeIconStyle} />
                </IconButton>
              </Stack>
            </Stack>
            <Divider sx={{ mb: 1 }} />
            <Stack gap={2.4}>
              {selectedView?.componentType !== NAVIGATION_COMPONENT_TYPE.CHART &&
                selectedView?.componentType !== NAVIGATION_COMPONENT_TYPE.EVENT && (
                  <>
                    <Stack>
                      <Stack width='100%'>
                        <Typography variant='input-label-gray' mb={1}>
                          {`Select a member as default filter for ${selectedView?.componentName}.`}
                        </Typography>
                      </Stack>
                      <Stack direction='row' gap={1}>
                        <Autocomplete
                          onChange={(event, value) => {
                            setOptionValue((prev) => ({ ...prev, member: value }));
                          }}
                          clearIcon={null}
                          sx={{
                            width: 300,
                          }}
                          size='small'
                          value={optionValue.member}
                          getOptionLabel={(option: AutoCompleteItem) => option.label}
                          options={memberOptions || []}
                          renderOption={(props, option, state) =>
                            renderOptionsKtree(props, option, state, lowestMemberLevel)
                          }
                          ListboxProps={{
                            ...getListBoxPropsAutoScrolItem(memberOptions, optionValue.member),
                          }}
                          renderInput={(params) => (
                            <Stack direction='row' justifyContent='space-between' display='flex' alignItems='center'>
                              <TextField {...params} label={isFetchingMember ? 'Loading...' : 'Member'} name='member' />
                            </Stack>
                          )}
                        />
                      </Stack>
                    </Stack>
                    <Stack>
                      <Stack width='100%'>
                        <Typography variant='input-label-gray' mb={1}>
                          {`Select a additional drill down cube for ${selectedView?.componentName}.`}
                        </Typography>
                      </Stack>
                      <Stack direction='row' gap={1}>
                        <Autocomplete
                          onChange={(event, value) => {
                            setOptionValue((prev) => ({ ...prev, additionalWorkFlows: value }));
                          }}
                          sx={{
                            width: 300,
                          }}
                          size='small'
                          value={optionValue.additionalWorkFlows}
                          getOptionLabel={(option: AutoCompleteItem) => option.label}
                          options={additionalWorkFlowsDropdown?.data || []}
                          renderOption={renderOption}
                          renderInput={(params) => (
                            <Stack direction='row' justifyContent='space-between' display='flex' alignItems='center'>
                              <TextField
                                {...params}
                                label={isFetchingMember ? 'Loading...' : 'Additional Cube'}
                                name='cube'
                              />
                            </Stack>
                          )}
                        />
                      </Stack>
                    </Stack>
                    <Stack>
                      <Stack width='100%'>
                        <Typography variant='input-label-gray' mb={2}>
                          {`Select the default dimension filter for ${selectedView?.componentName}.`}
                        </Typography>
                      </Stack>
                      <Stack direction='row' gap={1}>
                        <Autocomplete
                          onChange={(event, value) => {
                            setOptionValue((prev) => ({
                              ...prev,
                              defaultXDimensionType: value,
                            }));
                          }}
                          clearIcon={null}
                          size='small'
                          value={optionValue.defaultXDimensionType}
                          getOptionLabel={(option: AutoCompleteItem) => option.label}
                          options={xDimensionOptions}
                          renderOption={renderOption}
                          sx={autoCompleteStyle}
                          renderInput={(params) => (
                            <Stack direction='row' justifyContent='space-between' display='flex' alignItems='center'>
                              <TextField
                                {...params}
                                label={'Down'}
                                InputProps={{
                                  ...params.InputProps,
                                  startAdornment: (
                                    <InputAdornment position='start'>
                                      <CubeDown />
                                    </InputAdornment>
                                  ),
                                }}
                                name='xDimensionType'
                              />
                            </Stack>
                          )}
                        />
                        <Autocomplete
                          onChange={(event, value) => {
                            setOptionValue((prev) => ({
                              ...prev,
                              defaultYDimensionType: value,
                            }));
                          }}
                          clearIcon={null}
                          size='small'
                          value={optionValue.defaultYDimensionType}
                          getOptionLabel={(option: AutoCompleteItem) => option.label}
                          options={yDimensionOptions}
                          renderOption={renderOption}
                          sx={autoCompleteStyle}
                          renderInput={(params) => (
                            <Stack direction='row' justifyContent='space-between' display='flex' alignItems='center'>
                              <TextField
                                {...params}
                                label='Across'
                                InputProps={{
                                  ...params.InputProps,
                                  startAdornment: (
                                    <InputAdornment position='start'>
                                      <CubeAccrossIcon />
                                    </InputAdornment>
                                  ),
                                }}
                                name='yDimenstionType'
                              />
                            </Stack>
                          )}
                        />
                      </Stack>
                      <Stack mt={3} height={100}>
                        {showDefaultDimensionMemberFilter && (
                          <Autocomplete
                            onChange={(event, value) => {
                              setOptionValue((prev) => ({ ...prev, defaultFilterMember: value }));
                            }}
                            clearIcon={null}
                            sx={{
                              width: 300,
                            }}
                            size='small'
                            value={optionValue.defaultFilterMember}
                            getOptionLabel={(option: AutoCompleteItem) => option.label}
                            options={dimensionMemberOptions || []}
                            renderOption={(props, option, state) =>
                              renderOptionsKtree(props, option, state, lowestDimensionMemberLevel)
                            }
                            ListboxProps={{
                              ...getListBoxPropsAutoScrolItem(dimensionMemberOptions, optionValue.defaultFilterMember),
                            }}
                            renderInput={(params) => (
                              <Stack direction='row' justifyContent='space-between' display='flex' alignItems='center'>
                                <TextField
                                  {...params}
                                  label={isFetchingDimensionMember ? 'Loading...' : dimensionFilterLabel}
                                  name='member'
                                />
                              </Stack>
                            )}
                          />
                        )}
                      </Stack>
                    </Stack>
                  </>
                )}

              {selectedView?.componentType === NAVIGATION_COMPONENT_TYPE.CHART && (
                <Stack width='100%'>
                  <Typography variant='input-label-gray' mb={2}>
                    {`Set default chart measurment and default offset time period for ${selectedView?.componentName}.`}
                  </Typography>
                  <Stack
                    flexDirection='row'
                    justifyContent='space-between'
                    gap={2}
                    alignItems='flex-start'
                    width='100%'
                  >
                    <Stack width='50%'>
                      <Autocomplete
                        // @ts-ignore
                        onChange={(event, value) => {
                          setOptionValue((prev) => ({
                            ...prev,
                            defaultMeasurement: value,
                          }));
                        }}
                        clearIcon={null}
                        size='small'
                        value={{
                          label: optionValue.defaultMeasurement?.label || 'Measurement',
                          value: optionValue.defaultMeasurement?.value || '',
                        }}
                        isOptionEqualToValue={(option: AutoCompleteItem, value: AutoCompleteItem) =>
                          option.value === value.value
                        }
                        disabled={isFetchingMeasurement}
                        getOptionLabel={(option: AutoCompleteItem) => option.label}
                        options={chartViewPanel?.data.measureOptions || []}
                        renderOption={(props, option) => (
                          <Box
                            component='li'
                            sx={{ '& > span': { fontSize: '14px', mr: 1, flexShrink: 0 } }}
                            {...props}
                          >
                            <span>{option.label}</span>
                          </Box>
                        )}
                        sx={autoCompleteStyle}
                        renderInput={(params) => (
                          <Stack direction='row' justifyContent='space-between' display='flex' alignItems='center'>
                            <TextField
                              {...params}
                              label={isFetchingMember ? 'Loading...' : 'Measurement'}
                              placeholder={isFetchingMember ? 'Loading...' : 'Select Measurement'}
                              name='measureChartCubeAttribute'
                            />
                          </Stack>
                        )}
                      />
                    </Stack>
                    <Stack width='50%'>
                      <TextField
                        name='search'
                        size='small'
                        value={optionValue.currentPeriodOffset}
                        onChange={(event) => {
                          const regex = /^-?\d*$/;
                          const { value } = event.target;
                          if (regex.test(value)) {
                            // @ts-ignore
                            setOptionValue((prev) => ({
                              ...prev,
                              currentPeriodOffset: value,
                            }));
                          }
                        }}
                        placeholder='Enter current period adjustment'
                        label='Current Period Adjustment'
                        type='number'
                        inputProps={{
                          step: 1,
                        }}
                      />
                    </Stack>
                  </Stack>
                </Stack>
              )}
            </Stack>
          </Stack>
          <Stack px={2}>
            <Divider sx={{ mb: 1, mt: 2 }} />
            <Stack direction='row' justifyContent='flex-end' spacing={2} alignItems='center' sx={{ py: 1 }}>
              <Button variant='main-table-panel-border' onClick={handleOnCancel}>
                Cancel
              </Button>
              <ButtonLoading variant='main-table-panel' disabled={!isValidForm} onClick={() => handleOnSave()}>
                Update Setting
              </ButtonLoading>
            </Stack>
          </Stack>
        </Stack>
      </Modal>
    </div>
  );
}
