import { useEffect, useImperativeHandle, useMemo, useRef, useState, forwardRef, useCallback } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { debounce } from 'lodash';
import { Chip } from '@mui/material';
import { Formik, FormikProps } from 'formik';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Checkbox from '@mui/material/Checkbox';

import { DropdownItem } from 'types/api/SystemTenant/AROKMS/TableDefinitionTypes';
import { flattenTreeData, renderOptionsKtree, getListBoxPropsAutoScrolItem } from 'utils/Object';
import { AutoCompleteItem } from 'types/api/Common/AutoCompleteTypes';
import { ControllerNavigation, ControllerNavigationDirection } from 'pages/Tenant/AroDashboard/ControllerNavigation';
import { DASHBOARD_DISPLAY_TYPE, NAVIGATION_COMPONENT_TYPE } from 'constant/NavigationConstant';
import { PAGE_REF_NAVIGATION } from 'utils/Animation';
import {
  WorkFlowDrillNavigationDirection,
  getCurrentMyViewIndex,
  getNextDrillDownURL,
  getNextMyViewItem,
  getPreviousMyViewItem,
  getWorkFlowDrillView,
} from 'utils/MyViewUtils';
import { ChartViewPanelOptionsValue } from 'types/api/Tenant/AROChart/ChartViewTypes';
import { KTreeNode } from 'types/api/Tenant/AROKMS/KTreeDataTypes';
import { ChartViewType } from 'constant/ChartViewConstant';
import { getQuickAccessNavigateURL } from 'components/LayoutComponent/VerticalLayout/QuickAccess';
import { useGetChartViewPanelOptions } from 'services/v1/Tenant/AROChart/ChartViewService';
import { useGetChartViewDetails } from 'services/v1/SystemTenant/AROChart/ChartViewDefinitionService';
import { useGetMyPersonalization, useGetMyViewDetails } from 'services/v1/Tenant/ConfigurationService';
import { useGetCubeDefinitionDetails } from 'services/v1/SystemTenant/AROCube/CubeBuilderService';

export interface OptionItem {
  value: string | number;
  label: string;
}

const autoCompleteStyle = {
  width: 230,
  '& .MuiAutocomplete-popper': {
    backgroundColor: 'red !imoprtant',
    fontSize: '10px',
  },
};

const textInputStyle = {};

interface ChartViewPanelProps {
  disabled?: boolean;
  ref?: any;
  onOptionChange?: (option: ChartViewPanelOptionsValue) => void;
  loading?: boolean;
  setIsLoadingOptionsData?: (isLoading: boolean) => void;
}

const formikInitialValues: ChartViewPanelOptionsValue = {
  members: null,
  accumulation: null,
  layer: null,
  measure: null,
};

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;

export function filterCrossLevelSelections(newSelection: KTreeNode[], nextAddedItem: KTreeNode | null) {
  if (!nextAddedItem) return newSelection; // e.g. removing items from selection

  const uniqueLevels = new Set(newSelection.map((item) => item.level));

  // Case 1: No items yet => just accept
  if (uniqueLevels.size === 0) {
    return newSelection;
  }

  // If we already have a certain level in the selection and
  // the nextAddedItem is from a different level => clear or block
  const currentLevel = uniqueLevels.values().next().value; // get the single existing level
  if (nextAddedItem.level !== currentLevel) {
    // Option A: Clear the old selection, allow new
    return [nextAddedItem];

    // Option B: Or block the new selection by returning old selection only
    // return newSelection;
  }

  // Otherwise, it's the same level, allow the addition
  return newSelection;
}

export function filterOptionsBySelectedLevel(allOptions: KTreeNode[], selectedValues: KTreeNode[]): KTreeNode[] {
  // Determine which level(s) the user currently has selected
  // @ts-ignore
  const uniqueLevels = [...new Set(selectedValues.map((item) => item.level))];

  // If no selection or if we somehow have more than one level
  // (which shouldn't happen in your single-level constraint),
  // then show all options by default.
  if (uniqueLevels.length !== 1) {
    return allOptions;
  }

  // Otherwise, show only those options with the selected level
  const selectedLevel = uniqueLevels[0];
  return allOptions.filter((opt) => opt.level === selectedLevel);
}

export const ChartViewPanel = forwardRef((props: ChartViewPanelProps, ref) => {
  const { disabled: disableProps, onOptionChange, setIsLoadingOptionsData } = props;

  const navigate = useNavigate();
  const { chartViewId } = useParams<{ chartViewId: string }>();
  const [searchParams] = useSearchParams();
  const myViewId = searchParams.get('myViewId');
  const workFlowId = searchParams.get('workFlowId');
  const memberId = searchParams.get('memberId');
  const chartMeasurmentId = searchParams.get('chartMeasurmentId');
  const defaultFilterMember = searchParams.get('defaultFilterMember');
  const memberIds: string[] | undefined =
    searchParams.get('memberIds') !== null ? searchParams.getAll('memberIds') : undefined;

  const [selectedValues, setSelectedValues] = useState<KTreeNode[]>([]);

  const { data: allMyViewsData } = useGetMyPersonalization({ enabled: true });
  const { data: myViewDetails } = useGetMyViewDetails(myViewId);

  const { data: chartViewDefintition } = useGetChartViewDetails(chartViewId);
  const { data: cubeDefinitionDetails } = useGetCubeDefinitionDetails(
    chartViewDefintition?.data.cube?.value?.toString()
  );
  const { data: chartViewPanel, isLoading: isFetchingMember } = useGetChartViewPanelOptions(
    chartViewId,
    myViewId || undefined
  );

  const chartViewList = useMemo(() => {
    if (allMyViewsData?.data.displayType === DASHBOARD_DISPLAY_TYPE.DASHBOARD_LIST) {
      const selectedDashboardId = myViewDetails?.data.dashboardId;
      const selectedDashboard =
        allMyViewsData.data.dashboards.find((dashboardItem) => dashboardItem.id === selectedDashboardId) || null;

      if (selectedDashboard) {
        return selectedDashboard.myViews;
      }
    }

    return (
      allMyViewsData?.data.myViewItems.filter((item) => item.componentType === NAVIGATION_COMPONENT_TYPE.CHART) ?? []
    );
  }, [allMyViewsData?.data, myViewDetails?.data]);

  const [optionValue, setOptionValue] = useState<ChartViewPanelOptionsValue>({
    members: null,
    accumulation: null,
    measure: null,
    layer: null,
  });
  const formikRef = useRef<FormikProps<ChartViewPanelOptionsValue>>(null);

  const disabled = disableProps;

  const memberOptions = useMemo(() => {
    if (chartViewPanel?.data.memberOptions) {
      return flattenTreeData(chartViewPanel?.data.memberOptions);
    }
    return [];
  }, [chartViewPanel?.data.memberOptions]);

  const lowestMemberLevel = useMemo(() => {
    // @ts-ignore
    return memberOptions.reduce((acc, item) => {
      if (item.level > acc) {
        return item.level;
      } else {
        return acc;
      }
    }, 0);
  }, [memberOptions]);

  const secondHighestMemberLevel = useMemo(() => {
    if (
      !memberOptions ||
      memberOptions.length < 2 ||
      chartViewDefintition?.data?.type !== ChartViewType.MULTI_MEMBER_SELECTION
    ) {
      return null; // Return null if there are not enough options
    }

    // @ts-ignore
    const levels = memberOptions.map((item) => item.level).sort((a, b) => b + a);
    return levels[1]; // The second highest level
  }, [memberOptions, chartViewDefintition?.data.type]);

  const currentMyViewItem = useMemo(() => {
    if (myViewDetails?.data) {
      return myViewDetails.data;
    }
    return null;
  }, [myViewDetails?.data]);

  const currentMyViewIndex = useMemo(() => {
    if (myViewId == null) return 0;
    return getCurrentMyViewIndex(chartViewList, myViewId);
  }, [myViewId, chartViewList]);

  const nextViewItem = useMemo(() => {
    return getNextMyViewItem(chartViewList, currentMyViewIndex);
  }, [currentMyViewIndex, chartViewList]);

  const previousViewItem = useMemo(() => {
    return getPreviousMyViewItem(chartViewList, currentMyViewIndex);
  }, [currentMyViewIndex, chartViewList]);

  const nextDrilDownWorkFlow = useMemo(() => {
    return getWorkFlowDrillView(currentMyViewItem, workFlowId, WorkFlowDrillNavigationDirection.DOWN);
  }, [currentMyViewItem, workFlowId]);

  const previousDrilDownWorkFlow = useMemo(() => {
    return getWorkFlowDrillView(currentMyViewItem, workFlowId, WorkFlowDrillNavigationDirection.UP);
  }, [currentMyViewItem, workFlowId]);

  const mainMemberFilterLabel = useCallback(() => {
    if (cubeDefinitionDetails?.data) {
      return cubeDefinitionDetails?.data.member.label;
    }
    return 'Member';
  }, [cubeDefinitionDetails?.data]);

  const handleOnDashboardNavigate = (direction: ControllerNavigationDirection) => {
    // if (!cubeDefinitionId) return;
    switch (direction) {
      case ControllerNavigationDirection.UP:
        const previousDrillDownURL = getNextDrillDownURL(currentMyViewItem, previousDrilDownWorkFlow, {
          memberId: optionValue.members?.[0]?.value,
          ref: PAGE_REF_NAVIGATION.TOP,
        });
        previousDrillDownURL && navigate(previousDrillDownURL);
        break;
      case ControllerNavigationDirection.DOWN:
        const nextDrillDownURL = getNextDrillDownURL(currentMyViewItem, nextDrilDownWorkFlow, {
          memberId: optionValue.members?.[0]?.value,
          cubeId: currentMyViewItem?.cubeId,
          workFlowId: nextDrilDownWorkFlow?.id,
          ref: PAGE_REF_NAVIGATION.BOTTOM,
          chartMeasurmentId: optionValue.measure?.value,
          refChartId: chartViewId,
          memberIds: selectedValues.length > 0 ? selectedValues.map((item) => item.value) : undefined,
        });
        nextDrillDownURL && navigate(nextDrillDownURL);
        break;

      case ControllerNavigationDirection.LEFT:
        const previousMyViewItemURL = getQuickAccessNavigateURL(previousViewItem, undefined, PAGE_REF_NAVIGATION.LEFT);
        previousMyViewItemURL && navigate(previousMyViewItemURL);
        break;

      case ControllerNavigationDirection.RIGHT:
        const nextMyViewItemURL = getQuickAccessNavigateURL(nextViewItem, undefined, PAGE_REF_NAVIGATION.RIGHT);
        nextMyViewItemURL && navigate(nextMyViewItemURL);

        break;

      default:
        break;
    }
  };

  const debouncedFunction = useMemo(
    () =>
      debounce((filteredValue: KTreeNode[]) => {
        setOptionValue((prev) => ({ ...prev, members: filteredValue }));
      }, 1000),
    [setOptionValue]
  );

  const debouncedSetOptionValue = useCallback(
    (filteredValue: KTreeNode[]) => {
      debouncedFunction(filteredValue);
    },
    [debouncedFunction]
  );

  const handleMemberChange = (event: React.SyntheticEvent, newValue: KTreeNode[]) => {
    // 1. Determine which item was just added/removed
    //    MUI provides `newValue` (the entire new array).
    //    Compare newValue to the old `selectedValues` to see the difference.

    let nextAddedItem = null;

    // If newValue has more items than old, we know an item was added.
    if (newValue.length > selectedValues.length) {
      // The newly added item is the last item in newValue
      nextAddedItem = newValue[newValue.length - 1];
    }

    // 2. Filter cross-level selections
    const filteredValue = filterCrossLevelSelections(newValue, nextAddedItem);

    // 3. Update your state
    setSelectedValues(filteredValue);
    formikRef.current?.setFieldValue('members', filteredValue);

    debouncedSetOptionValue(filteredValue);
  };

  const controllerNaviations = useMemo(() => {
    const controller = [];
    if (nextDrilDownWorkFlow && previousDrilDownWorkFlow) {
      controller.push(ControllerNavigationDirection.UP);
      controller.push(ControllerNavigationDirection.DOWN);
    }
    if (previousDrilDownWorkFlow && !nextDrilDownWorkFlow) {
      controller.push(ControllerNavigationDirection.UP);
    }
    if (!previousDrilDownWorkFlow && nextDrilDownWorkFlow) {
      controller.push(ControllerNavigationDirection.DOWN);
    }

    if (chartViewList?.length > 1) {
      const currentInsightIndex = chartViewList.findIndex((item) => item.value === chartViewId);
      if (currentInsightIndex > 0) controller.push(ControllerNavigationDirection.LEFT);
      if (currentInsightIndex < chartViewList.length - 1) controller.push(ControllerNavigationDirection.RIGHT);
    }

    return controller;
  }, [chartViewList, chartViewId, nextDrilDownWorkFlow, previousDrilDownWorkFlow]);

  const navigationControllerTooltips = useMemo(() => {
    const defaultToolTips: {
      [key: string]: string;
    } = {
      [ControllerNavigationDirection.UP]: previousDrilDownWorkFlow?.componentName
        ? `Show ${previousDrilDownWorkFlow.componentName}`
        : 'Show Chart',
      [ControllerNavigationDirection.DOWN]: `Show ${nextDrilDownWorkFlow?.componentName}`,
    };

    if (chartViewList.length > 1) {
      const currentInsightIndex = chartViewList.findIndex((item) => item.value === chartViewId);
      if (currentInsightIndex > -1) {
        if (currentInsightIndex > 0) {
          defaultToolTips[ControllerNavigationDirection.LEFT] = `Show ${chartViewList[currentInsightIndex - 1].title}`;
        }
        if (currentInsightIndex < chartViewList.length - 1) {
          defaultToolTips[ControllerNavigationDirection.RIGHT] = `Show ${chartViewList[currentInsightIndex + 1].title}`;
        }
      }
    }

    return defaultToolTips;
  }, [nextDrilDownWorkFlow, previousDrilDownWorkFlow, chartViewList, chartViewId]);

  const memberMultipleSelectionOptions: KTreeNode[] = useMemo(() => {
    return filterOptionsBySelectedLevel(memberOptions, selectedValues);
  }, [memberOptions, selectedValues]);

  useEffect(() => {
    if (secondHighestMemberLevel != null && memberOptions.length > 0) {
      const selectedMembers = memberOptions.filter((item: KTreeNode) => item.level === secondHighestMemberLevel);

      if (selectedMembers.length > 0) {
        console.log('selectedMembers', selectedMembers);
        setOptionValue((prev) => ({ ...prev, members: selectedMembers }));
        setSelectedValues(selectedMembers);
        formikRef.current?.setFieldValue('members', selectedMembers);
      }
    }
  }, [secondHighestMemberLevel, memberOptions]);

  useEffect(() => {
    onOptionChange && onOptionChange(optionValue);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionValue]);

  const layerOptions: AutoCompleteItem[] = useMemo(() => {
    if (chartViewPanel?.data.insightAttributeOptions && optionValue.accumulation) {
      return chartViewPanel?.data.insightAttributeOptions
        .filter((item) => item.type === optionValue.accumulation?.value)
        .map((item) => ({
          value: item.value,
          label: item.label,
        }));
    }
    return [];
  }, [chartViewPanel?.data.insightAttributeOptions, optionValue.accumulation]);

  useEffect(() => {
    if (!memberOptions || memberOptions.length === 0) return;

    let selectedMembers: KTreeNode[] = [];

    if (memberIds) {
      selectedMembers = memberOptions.filter((item: KTreeNode) => memberIds.includes(item.value.toString()));
      setSelectedValues(selectedMembers);
    } else {
      selectedMembers =
        secondHighestMemberLevel != null
          ? memberOptions.filter((item: KTreeNode) => item.level === secondHighestMemberLevel)
          : [];
    }

    const member = memberOptions.find((item: AutoCompleteItem) => item.value === memberId) ?? memberOptions[0];
    const measure =
      chartViewPanel?.data.measureOptions.find((item) => item.value === chartMeasurmentId) ??
      chartViewPanel?.data.measureOptions[0];
    const initAccumulation = chartViewPanel?.data.accumulationOptions?.[0];

    const selectedMember = {
      value: member?.value?.toString() || '',
      label: member?.label,
      level: member?.level,
      children: [],
    };

    const selectedMeasure = chartViewPanel?.data.selectedMeasurement || {
      value: measure?.value?.toString() || '',
      label: measure?.label || 'Measurement',
    };

    const selectedAccumulation = {
      value: initAccumulation?.value?.toString() || '',
      label: initAccumulation?.label || 'Accumulation',
    };

    const allLayersOptions = chartViewPanel?.data.insightAttributeOptions
      .filter((item) => item.type === selectedAccumulation?.value)
      .map((item) => ({
        value: item.value,
        label: item.label,
      }));

    const selectedLayer = allLayersOptions?.[0] || { value: '', label: 'Layer' };

    setOptionValue((prev) => {
      const isSameMember = prev.members?.[0]?.value === selectedMember.value;
      const isSameMeasure = prev.measure?.value === selectedMeasure.value;
      const isSameAccumulation = prev.accumulation?.value === selectedAccumulation.value;
      const isSameLayer = prev.layer?.value === selectedLayer.value;

      // If there's no change, bail out to avoid extra re-renders
      if (isSameMember && isSameMeasure && isSameAccumulation && isSameLayer) {
        return prev; // No change -> don't update state -> no re-render loop
      }

      return {
        ...prev,
        members: secondHighestMemberLevel == null ? [selectedMember] : selectedMembers,
        measure: selectedMeasure,
        accumulation: selectedAccumulation,
        layer: selectedLayer,
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    memberOptions,
    memberId,
    chartMeasurmentId,
    chartViewPanel?.data.measureOptions,
    chartViewPanel?.data.accumulationOptions,
    chartViewPanel?.data.insightAttributeOptions,
    chartViewPanel?.data.selectedMeasurement,
    secondHighestMemberLevel,
  ]);

  useEffect(() => {
    if (memberOptions.length > 0 && defaultFilterMember) {
      const member =
        memberOptions.find((item: DropdownItem) => item.value === defaultFilterMember) ?? memberOptions?.[0];

      if (member && chartViewDefintition?.data.type === ChartViewType.SINGLE_MEMBER_SELECTION) {
        setOptionValue((prev) => ({ ...prev, members: [member] }));
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberOptions, defaultFilterMember, chartViewDefintition?.data.type]);

  useEffect(() => {
    if (layerOptions.length > 0 && chartViewDefintition?.data?.type === ChartViewType.MULTI_MEMBER_SELECTION) {
      setOptionValue((prev) => ({ ...prev, layer: layerOptions[0] }));
    }
  }, [layerOptions, chartViewDefintition?.data.type]);

  useImperativeHandle(ref, () => ({
    isLoadingOptionsData: isFetchingMember,
  }));

  useEffect(() => {
    setIsLoadingOptionsData && setIsLoadingOptionsData(isFetchingMember);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetchingMember]);

  return (
    <Stack direction='column' sx={{ backgroundColor: '#fff', py: 1 }}>
      <Formik onSubmit={() => {}} innerRef={formikRef} initialValues={formikInitialValues}>
        {({ errors, touched, handleBlur, handleChange, values, setFieldValue, handleSubmit }) => {
          return (
            <>
              <Stack
                direction='row'
                alignItems='center'
                sx={{ bgcolor: '#FBFBFB', py: 1, px: 2, border: '1px solid #E3EBF6' }}
              >
                <Stack direction='row' justifyContent='space-between' width='100%'>
                  <Stack direction='row' spacing={5} alignItems='center'>
                    {chartViewDefintition?.data.type === ChartViewType.SINGLE_MEMBER_SELECTION && (
                      <Autocomplete
                        // @ts-ignore
                        onChange={(event, value) => {
                          if (value) {
                            setOptionValue((prev) => ({ ...prev, members: [value as KTreeNode] }));
                          }
                        }}
                        clearIcon={null}
                        sx={{
                          width: 300,
                        }}
                        size='small'
                        value={{
                          label: optionValue.members?.[0]?.label || 'Member',
                          value: optionValue.members?.[0]?.value || '',
                        }}
                        isOptionEqualToValue={(option: AutoCompleteItem, value: AutoCompleteItem) =>
                          option.value === value.value
                        }
                        disabled={disabled || isFetchingMember}
                        getOptionLabel={(option: AutoCompleteItem) => option.label}
                        options={memberOptions || []}
                        renderOption={(props, option, state) =>
                          renderOptionsKtree(props, option, state, lowestMemberLevel)
                        }
                        ListboxProps={{
                          ...getListBoxPropsAutoScrolItem(memberOptions, optionValue.members?.[0]),
                        }}
                        renderInput={(params) => (
                          <Stack direction='row' justifyContent='space-between' display='flex' alignItems='center'>
                            <TextField
                              {...params}
                              sx={textInputStyle}
                              label={isFetchingMember ? 'Loading...' : mainMemberFilterLabel() || 'Member'}
                              onBlur={handleBlur}
                              name='member'
                            />
                          </Stack>
                        )}
                      />
                    )}

                    {chartViewDefintition?.data.type === ChartViewType.MULTI_MEMBER_SELECTION && (
                      <Autocomplete
                        multiple
                        onChange={handleMemberChange}
                        sx={{
                          width: 350,
                        }}
                        size='small'
                        value={selectedValues}
                        isOptionEqualToValue={(option: KTreeNode, value: KTreeNode) => option.value === value.value}
                        getOptionDisabled={(option) => {
                          // If we have a selected level (say `selectedLevel`), only enable
                          // items from that level. If no selection, no disabling.
                          const selectedLevelSet = new Set(selectedValues.map((item) => item.level));
                          if (selectedLevelSet.size === 0) return false; // no selection yet => enable all
                          if (selectedLevelSet.size === 1) {
                            // @ts-ignore
                            const [selectedLevel] = [...selectedLevelSet];
                            return option.level !== selectedLevel;
                          }
                          return false;
                        }}
                        disabled={disabled || isFetchingMember}
                        getOptionLabel={(option: KTreeNode) => option.label}
                        disableCloseOnSelect
                        renderTags={(value, getTagProps) => {
                          if (value.length === 0) return null;
                          return (
                            <Chip
                              label={`${value.length} item${value.length > 1 ? 's' : ''} selected`}
                              color='primary'
                            />
                          );
                        }}
                        options={memberMultipleSelectionOptions || []}
                        renderOption={(
                          props: React.HTMLAttributes<HTMLLIElement>,
                          option: AutoCompleteItem,
                          { selected }
                        ) => {
                          // @ts-ignore
                          const isLowestLevel = option.level === lowestMemberLevel;
                          const styleObj: React.CSSProperties = isLowestLevel
                            ? {
                                // @ts-ignore
                                marginLeft: `${option.level * 3}px`,
                                fontSize: '14px',
                              }
                            : {
                                fontWeight: 'bold',
                                // @ts-ignore
                                marginLeft: `${option.level * 2}px`,
                                borderBottom: '1px solid #e3e3e3',
                                fontSize: '14px',
                              };

                          return (
                            <li {...props} key={option.value} style={styleObj}>
                              <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                                sx={{
                                  color: '#828C99',
                                  px: 0,
                                  paddingRight: 0.5,
                                }}
                              />
                              {option.label}
                            </li>
                          );
                        }}
                        renderInput={(params) => (
                          <Stack direction='row' justifyContent='space-between' display='flex' alignItems='center'>
                            <TextField
                              {...params}
                              sx={textInputStyle}
                              label={isFetchingMember ? 'Loading...' : mainMemberFilterLabel() || 'Member'}
                              onBlur={handleBlur}
                              name='member'
                              value={`Selected va`}
                            />
                          </Stack>
                        )}
                      />
                    )}

                    <Autocomplete
                      // @ts-ignore
                      onChange={(event, value) => {
                        setOptionValue((prev) => ({
                          ...prev,
                          measure: value,
                        }));
                      }}
                      clearIcon={null}
                      size='small'
                      value={{
                        label: optionValue.measure?.label || 'Measurement',
                        value: optionValue.measure?.value || '',
                      }}
                      isOptionEqualToValue={(option: AutoCompleteItem, value: AutoCompleteItem) =>
                        option.value === value.value
                      }
                      disabled={disabled || isFetchingMember}
                      getOptionLabel={(option: AutoCompleteItem) => option.label}
                      options={chartViewPanel?.data.measureOptions || []}
                      renderOption={(props, option) => (
                        <Box component='li' sx={{ '& > span': { fontSize: '14px', mr: 1, flexShrink: 0 } }} {...props}>
                          <span>{option.label}</span>
                        </Box>
                      )}
                      sx={autoCompleteStyle}
                      renderInput={(params) => (
                        <Stack direction='row' justifyContent='space-between' display='flex' alignItems='center'>
                          <TextField
                            {...params}
                            sx={textInputStyle}
                            label={isFetchingMember ? 'Loading...' : 'Measurement'}
                            onBlur={handleBlur}
                            placeholder={isFetchingMember ? 'Loading...' : 'Select Measurement'}
                            name='measureChartCubeAttribute'
                          />
                        </Stack>
                      )}
                    />

                    <Autocomplete
                      // @ts-ignore
                      onChange={(event, value) => {
                        setOptionValue((prev) => ({
                          ...prev,
                          accumulation: value,
                        }));
                      }}
                      clearIcon={null}
                      size='small'
                      isOptionEqualToValue={(option: AutoCompleteItem, value: AutoCompleteItem) =>
                        option.value === value.value
                      }
                      value={{
                        label: optionValue.accumulation?.label || 'Accumulation',
                        value: optionValue.accumulation?.value || '',
                      }}
                      disabled={disabled || isFetchingMember}
                      getOptionLabel={(option: AutoCompleteItem) => option.label}
                      options={chartViewPanel?.data.accumulationOptions || []}
                      renderOption={(props, option) => (
                        <Box component='li' sx={{ '& > span': { fontSize: '14px', mr: 1, flexShrink: 0 } }} {...props}>
                          <span>{option.label}</span>
                        </Box>
                      )}
                      sx={autoCompleteStyle}
                      renderInput={(params) => (
                        <Stack direction='row' justifyContent='space-between' display='flex' alignItems='center'>
                          <TextField
                            {...params}
                            sx={textInputStyle}
                            label={isFetchingMember ? 'Loading...' : 'Accumulation'}
                            onBlur={handleBlur}
                            placeholder={isFetchingMember ? 'Loading...' : 'Select Accumulation'}
                            name='accumulation'
                          />
                        </Stack>
                      )}
                    />

                    {chartViewDefintition?.data.type === ChartViewType.MULTI_MEMBER_SELECTION && (
                      <Autocomplete
                        // @ts-ignore
                        onChange={(event, value) => {
                          setOptionValue((prev) => ({
                            ...prev,
                            layer: value,
                          }));
                        }}
                        clearIcon={null}
                        size='small'
                        isOptionEqualToValue={(option: AutoCompleteItem, value: AutoCompleteItem) =>
                          option.value === value.value
                        }
                        value={{
                          label: optionValue.layer?.label || 'Layer',
                          value: optionValue.layer?.value || '',
                        }}
                        disabled={disabled || isFetchingMember}
                        getOptionLabel={(option: AutoCompleteItem) => option.label}
                        options={layerOptions}
                        renderOption={(props, option) => (
                          <Box
                            component='li'
                            sx={{ '& > span': { fontSize: '14px', mr: 1, flexShrink: 0 } }}
                            {...props}
                          >
                            <span>{option.label}</span>
                          </Box>
                        )}
                        sx={autoCompleteStyle}
                        renderInput={(params) => (
                          <Stack direction='row' justifyContent='space-between' display='flex' alignItems='center'>
                            <TextField
                              {...params}
                              sx={textInputStyle}
                              label={isFetchingMember ? 'Loading...' : 'Layer'}
                              onBlur={handleBlur}
                              placeholder={isFetchingMember ? 'Loading...' : 'Select Layer'}
                              name='layer'
                            />
                          </Stack>
                        )}
                      />
                    )}
                  </Stack>
                </Stack>
                <ControllerNavigation
                  enableNavigations={controllerNaviations}
                  onNavigate={handleOnDashboardNavigate}
                  tooltips={navigationControllerTooltips}
                />
              </Stack>

              <Stack direction='row' alignItems='center' sx={{ pt: 1, px: 2 }} width='100%'>
                <Stack direction='row' gap={3} width='100%' alignItems='center'></Stack>
              </Stack>
            </>
          );
        }}
      </Formik>
    </Stack>
  );
});
