import React, { useCallback, useState, useEffect } from 'react';
import { Box, Button, Divider, Stack, Tooltip } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import ViewInArOutlinedIcon from '@mui/icons-material/ViewInArOutlined';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { Responsive, Layout, Layouts, WidthProvider } from 'react-grid-layout';
import { BackToListButton } from 'components/ButtonComponent/ButtonBackToList';
import { AutoCompleteItem } from 'types/api/Common/AutoCompleteTypes';
import { ChartEditorComponentForm } from './ChartEditorComponentForm';
import { useFormik } from 'formik';
import { ChartItemComponent, getChartIconByType } from './ChartItemComponent';
import { ChartType } from 'constant/ChartViewConstant';

import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

const ResponsiveGridLayout = WidthProvider(Responsive);
const closeIconStyle = { color: '#98A2AE', cursor: 'pointer' };

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction='up' ref={ref} {...props} />;
});

export type GaugeChartParams = {
  min: number;
  max: number;
  color: string;
  label: string | null;
};

export type ChartItemComponentTypes = {
  id: string;
  name: string;
  chartType: string;
  description: string;
  xAxis: AutoCompleteItem;
  yAxis: AutoCompleteItem;
  gaugeParams: GaugeChartParams[];
};

type ChartEditorProps = {
  onClose?: () => void;
  open: boolean;
  chartViewName: string;
  onChanges?: (data: { charts: ChartItemComponentTypes[]; layouts: Layouts }) => void;
  initialValues?: {
    charts: ChartItemComponentTypes[];
    layouts: Layouts;
  };
};

function ChartTypeItem(props: {
  icon: React.ReactNode;
  title: string;
  chartType: string;
  onDragStart: () => void;
  onDragEnd: () => void;
}) {
  const handleOnDragStart = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.dataTransfer.setData(
        'application/reactgrid',
        JSON.stringify({
          chartType: props.chartType,
          name: 'Untitled Chart',
          description: null,
          xAxis: null,
          yAxis: null,
          gaugeParams:
            props.chartType === ChartType.GAUGE
              ? [
                  { min: 0, max: 100, color: '#42BB93', label: null },
                  { min: 101, max: 200, color: '#FFC107', label: null },
                  { min: 201, max: 300, color: '#F44336', label: null },
                ]
              : [],
        })
      );
      props.onDragStart();
    },
    [props]
  );

  const handleOnDragEnd = useCallback(() => {
    props.onDragEnd();
  }, [props]);

  return (
    <Stack width='100%' draggable onDragStart={handleOnDragStart} onDragEnd={handleOnDragEnd}>
      <Typography
        component='h5'
        textTransform='uppercase'
        fontWeight='bold'
        fontSize={12}
        mb={1}
        sx={{ color: '#999999', userSelect: 'none' }}
      >
        {props.title}
      </Typography>
      <Box
        sx={{
          height: 80,
          borderRadius: 2,
          bgcolor: '#f1f5f9',
          textAlign: 'center',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          border: '1px solid #f1f5f9',
          ':hover': { cursor: 'all-scroll', border: '1px solid #d6d4d4' },
        }}
      >
        <Stack
          sx={{
            borderRadius: 2,
            border: '1px solid #42BB93',
            padding: 2,
          }}
        >
          {props.icon}
        </Stack>
      </Box>
    </Stack>
  );
}

const CHART_TYPE_OPTIONS = [
  {
    title: 'Line Chart',
    type: ChartType.LINE,
    icon: getChartIconByType(ChartType.LINE),
  },
  {
    title: 'Bar Chart',
    type: ChartType.BAR,
    icon: getChartIconByType(ChartType.BAR),
  },
  {
    title: 'Pie Chart',
    type: ChartType.PIE,
    icon: getChartIconByType(ChartType.PIE),
  },
  {
    title: 'Row Chart',
    type: ChartType.ROW,
    icon: getChartIconByType(ChartType.ROW),
  },
  {
    title: 'Gauge Chart',
    type: ChartType.GAUGE,
    icon: getChartIconByType(ChartType.GAUGE),
  },
];

const MAX_ITEMS = 4;

const chartItemsInitialValue = {
  charts: [],
};

const initialEmptyLayouts = {
  lg: [],
  md: [],
  sm: [],
  xs: [],
  xxs: [],
};

function scaleLayoutItems(baseItems: Layout[], fromCols: number, toCols: number): Layout[] {
  // Avoid division by zero
  if (!fromCols || !toCols) return baseItems;

  // This ratio tells us how to scale widths (and positions)
  const ratio = toCols / fromCols;

  return baseItems.map((item) => {
    // Scale x, w to fit the new column count
    // const newX = Math.round(item.x * ratio);
    const newX = item.x * ratio;
    const newW = item.w * ratio;
    // const newW = Math.max(1, Math.round(item.w * ratio));

    // We can also scale y and h. This is optional:
    // const newY = Math.round(item.y * ratio);
    // const newH = Math.max(1, Math.round(item.h * ratio));

    const newY = item.y * ratio;
    const newH = item.h * ratio;
    return {
      ...item,
      x: newX,
      y: newY,
      w: newW,
      h: newH,
    };
  });
}

interface Breakpoints {
  [key: string]: number;
}

// We assume you're using a standard set of breakpoints + cols from above
function generateResponsiveLayouts(
  baseLayout: Layout[], // e.g., your updated layout in `lg`
  baseBreakpoint: string, // e.g., "lg"
  breakpoints: Breakpoints, // e.g., {lg:1200, md:996, ...}
  cols: { [key: string]: number } // e.g., {lg:12, md:10, ...}
): Layouts {
  const result: Layouts = {};
  const baseCols = cols[baseBreakpoint];

  // Create a layout for every breakpoint, using scaleLayoutItems
  Object.keys(breakpoints).forEach((bp) => {
    if (bp === baseBreakpoint) {
      // The base layout is used as-is
      result[bp] = baseLayout;
    } else {
      // Scale from baseBreakpoint's col count to this bp's col count
      result[bp] = scaleLayoutItems(baseLayout, baseCols, cols[bp]);
    }
  });
  return result;
}

export function ChartViewEditor(props: Readonly<ChartEditorProps>) {
  const { onClose, open, onChanges, initialValues, chartViewName } = props;
  const [layouts, setLayouts] = React.useState<Layouts>(initialEmptyLayouts);
  const [selectedIndexEdit, setSelectedIndexEdit] = useState<number | null>(null);
  const formik = useFormik<{
    charts: ChartItemComponentTypes[];
  }>({
    initialValues: chartItemsInitialValue,
    onSubmit: () => {},
  });

  const [isDragging, setIsDragging] = useState(false);
  const onDrop = (layout: Layout[], layoutItem: Layout, _event: DragEvent) => {
    setIsDragging(false);

    if (_event.dataTransfer) {
      const data = _event.dataTransfer.getData('application/reactgrid');
      if (!data) return;
      const newData = JSON.parse(data);
      const timestampUnix = new Date().getTime();
      const newItemId = `chart_${formik.values.charts.length + timestampUnix}`;
      const newItems = [...formik.values.charts, { id: newItemId, ...newData }];
      formik.setValues({ charts: newItems });

      // Add a corresponding layout item
      const newLayoutItem: Layout = {
        i: newItemId,
        x: 0,
        y: Infinity,
        w: 3,
        h: 4,
      };

      // Update the layouts state (for lg breakpoint)
      // setLayouts((prev) => {
      //   const updatedLg = [...(prev.lg || []), newLayoutItem];
      //   return { ...prev, lg: updatedLg };
      // });
      setLayouts((prev) => {
        return {
          ...prev,
          lg: [...(prev.lg || []), { ...newLayoutItem }],
          md: [...(prev.md || []), { ...newLayoutItem }],
          sm: [...(prev.sm || []), { ...newLayoutItem }],
          xs: [...(prev.xs || []), { ...newLayoutItem }],
          xxs: [...(prev.xxs || []), { ...newLayoutItem }],
        };
      });
    }
    // The layout is controlled by react-grid-layout, but we ensure stable items
    // If you need controlled layout, you can sync state here.
  };

  // For a responsive grid, you can set breakpoints and cols, or keep it simple
  const breakpoints = { lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 };
  const cols = { lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 };

  const handleDragStart = () => {
    // If we already have max items, we might also prevent highlighting
    if (formik.values.charts.length < MAX_ITEMS) {
      setIsDragging(true);
    }
  };

  const handleDragEnd = () => {
    setIsDragging(false);
  };

  const onLayoutChange = (newLayout: Layout[], allLayous: Layouts) => {
    // setLayouts((prev) => {
    //   return {
    //     ...prev,
    //     lg: newLayout,
    //     md: newLayout,
    //     sm: newLayout,
    //     xs: newLayout,
    //     xxs: newLayout,
    //   };
    // });
    const currentBreakpoint = 'lg'; // or detect dynamically

    // We'll overwrite *all* breakpoints with a scaled version of the current layout
    const newLayouts = generateResponsiveLayouts(newLayout, currentBreakpoint, breakpoints, cols);
    setLayouts(newLayouts);
  };

  const handleOnClose = () => {
    formik.setValues(chartItemsInitialValue);
    setLayouts(initialEmptyLayouts);
    setSelectedIndexEdit(null);
    onClose && onClose();
  };

  const handleBackToComponents = () => {
    setSelectedIndexEdit(null);
  };

  const handleOnButtonSaveClick = () => {
    onChanges &&
      onChanges({
        charts: formik.values.charts,
        layouts,
      });
    handleOnClose();
  };

  const handleOnDeleteChart = (index: number) => {
    setSelectedIndexEdit(null);
    const newCharts = formik.values.charts.filter((_, idx) => idx !== index);
    formik.setValues({ charts: newCharts });
    setLayouts((prev) => {
      const updatedLg = prev.lg.filter((item) => item.i !== formik.values.charts[index].id);
      return { ...prev, lg: updatedLg };
    });
  };

  useEffect(() => {
    if (initialValues) {
      setLayouts(initialValues?.layouts || initialEmptyLayouts);
      formik.setFieldValue('charts', initialValues?.charts || []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues]);

  return (
    <Dialog fullScreen open={open} onClose={handleOnClose} TransitionComponent={Transition}>
      <Stack px={2} py={1} justifyContent='space-between' direction='row' alignItems='center'>
        <Stack direction='row' gap={1} alignItems='center'>
          <ViewInArOutlinedIcon
            sx={{
              color: '#42BB93',
            }}
          />
          <Stack>
            <Typography variant='body1' component='h2' fontWeight='bold' sx={{ color: '#3B4797' }}>
              {chartViewName}
            </Typography>
            <Typography variant='caption' component='h5' fontWeight='bold' sx={{ color: '#999999' }}>
              Chart View Definition
            </Typography>
          </Stack>
        </Stack>
        <Stack direction={'row'} gap={2}>
          <Tooltip title="Save the changes you've made to this cube." arrow>
            <Button onClick={handleOnButtonSaveClick} startIcon={<SaveOutlinedIcon />} variant={'main-table-panel'}>
              Save
            </Button>
          </Tooltip>
          <IconButton onClick={handleOnClose} component='label'>
            <HighlightOffRoundedIcon sx={closeIconStyle} />
          </IconButton>
        </Stack>
      </Stack>
      <Divider />
      <Stack height='100%' direction='row' px={2} py={1} overflow='hidden' gap={2}>
        <Stack width='80%'>
          <ResponsiveGridLayout
            className='layout'
            style={{
              backgroundColor: '#e8eced',
              height: '75vh',
              width: '100%',
              borderRadius: 3,
              border: isDragging && formik.values.charts.length < MAX_ITEMS ? '2px dashed #42BB93' : 'none',
            }}
            layouts={layouts}
            breakpoints={breakpoints}
            cols={cols}
            rowHeight={30}
            isDroppable={true}
            onDrop={onDrop}
            onLayoutChange={onLayoutChange}
          >
            {formik.values.charts.map((item, idx) => (
              <div key={item.id}>
                <ChartItemComponent
                  chartType={item.chartType}
                  title={item.name}
                  onEdit={() => {
                    setSelectedIndexEdit(idx);
                  }}
                />
              </div>
            ))}
          </ResponsiveGridLayout>
        </Stack>
        <Divider orientation='vertical' flexItem />
        <Stack width='18%'>
          {selectedIndexEdit === null && (
            <>
              <Typography variant='h6' component='h3' fontWeight='bold' sx={{ color: '#000' }}>
                Charts
              </Typography>
              <Stack mt={2} width='100%' gap={2} flexDirection='row' flexWrap='wrap'>
                {CHART_TYPE_OPTIONS.map((chartType) => (
                  <Stack width='45%' key={chartType.type}>
                    <ChartTypeItem
                      key={chartType.type}
                      icon={chartType.icon}
                      chartType={chartType.type}
                      title={chartType.title}
                      onDragStart={handleDragStart}
                      onDragEnd={handleDragEnd}
                    />
                  </Stack>
                ))}
              </Stack>
            </>
          )}
          {selectedIndexEdit !== null && (
            <Stack mt={2} width='100%' gap={2} flexDirection='row' flexWrap='wrap'>
              <Stack width={200} alignItems='flex-start'>
                <BackToListButton title='Back to components' onClick={handleBackToComponents} />
              </Stack>
              <ChartEditorComponentForm index={selectedIndexEdit} formik={formik} onDelete={handleOnDeleteChart} />
            </Stack>
          )}
        </Stack>
      </Stack>
    </Dialog>
  );
}
