import { Box, Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import ActivityIndicator from 'components/ActivityIndicatorComponent';
import { EChartComponent } from 'pages/Tenant/AroDashboard/GaugeChart';
import { useGetChartViewDetails } from 'services/v1/SystemTenant/AROChart/ChartViewDefinitionService';
import { useGetCubeDefinitionDetails } from 'services/v1/SystemTenant/AROCube/CubeBuilderService';
import { useGetChartItemData } from 'services/v1/Tenant/AROChart/ChartViewService';
import { AutoCompleteItem } from 'types/api/Common/AutoCompleteTypes';
import eventEmitter, { EVENT_EMITTER_KEYS } from 'utils/EventEmitter';
import { ChartType } from 'constant/ChartViewConstant';

export function ChartItem(props: {
  chartItemId: string;
  memberIds: string[];
  cubeAttributeId: string;
  accumulationType: string;
  insightViewAttributeId?: string;
}) {
  const [searchParams] = useSearchParams();
  const myViewId = searchParams.get('myViewId') || undefined;
  const { chartViewId } = useParams<{ chartViewId: string }>();
  const { data: chartViewDefintition } = useGetChartViewDetails(chartViewId);
  const { data: cubeDefinitionDetails } = useGetCubeDefinitionDetails(
    chartViewDefintition?.data.cube?.value?.toString()
  );

  const kTreeName = useMemo(() => {
    if (cubeDefinitionDetails?.data) {
      return cubeDefinitionDetails?.data.member.label;
    }
    return null;
  }, [cubeDefinitionDetails?.data]);

  const { chartItemId, memberIds, cubeAttributeId, accumulationType, insightViewAttributeId } = props;
  const [chartFilters, setChartFilters] = useState<{ [key: string]: AutoCompleteItem }>({});
  const { data: chartDisplayData, isLoading: isLoadingChart } = useGetChartItemData({
    chartViewItemId: chartItemId,
    memberIds,
    cubeAttributeId,
    accumulationType,
    insightViewAttributeId,
    filters: Object.values(chartFilters),
    myViewId,
  });

  const handleOnChartFilterChange = (filterName: string, item: AutoCompleteItem) => {
    setChartFilters((prev) => ({
      ...prev,
      [filterName]: {
        value: item.value,
        label: filterName,
      },
    }));
  };

  const handleOnResponseLineChartClick = (timePeriodName: string) => {
    if (
      chartDisplayData?.data.chartViewItem.chartType === ChartType.GAUGE ||
      chartDisplayData?.data.chartViewItem.chartType === ChartType.PIE
    ) {
      setChartFilters((prev) => ({
        ...prev,
        timePeriod: {
          value: timePeriodName,
          label: 'timePeriod',
        },
      }));
    }
  };

  const handleOnLineChartClick = (timePeriodName: string) => {
    eventEmitter.emit(EVENT_EMITTER_KEYS.TRIGGER_CHART_TIME_PERIOD_FILTER_CHANGE, timePeriodName);
  };

  useEffect(() => {
    eventEmitter.on(EVENT_EMITTER_KEYS.TRIGGER_CHART_TIME_PERIOD_FILTER_CHANGE, handleOnResponseLineChartClick);

    return () => {
      eventEmitter.off(EVENT_EMITTER_KEYS.TRIGGER_CHART_TIME_PERIOD_FILTER_CHANGE, handleOnResponseLineChartClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartDisplayData?.data.chartViewItem.chartType]);

  if (memberIds.length === 0) {
    return (
      <Box
        sx={{
          width: '100%',
          height: '100%',
          bgcolor: '#ffffff',
          border: '1px solid #ccc',
          borderRadius: 2,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography variant='input-label'>Please select {kTreeName} to view the chart</Typography>
      </Box>
    );
  }

  if (isLoadingChart) {
    return (
      <Box
        sx={{
          width: '100%',
          height: '100%',
          bgcolor: '#ffffff',
          border: '1px solid #ccc',
          borderRadius: 2,
        }}
      >
        <ActivityIndicator />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        bgcolor: '#ffffff',
        border: '1px solid #ccc',
        borderRadius: 2,
      }}
    >
      {chartDisplayData?.data && (
        <EChartComponent
          chartDisplayData={chartDisplayData?.data}
          key={chartItemId}
          onFilterChange={handleOnChartFilterChange}
          onLineChartDataPointClick={handleOnLineChartClick}
        />
      )}
    </Box>
  );
}
