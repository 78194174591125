import { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { Stack } from '@mui/material';
import { Responsive, WidthProvider, Layouts } from 'react-grid-layout';
import { AutoCompleteItem } from 'types/api/Common/AutoCompleteTypes';
import { debounce } from 'lodash';
import { ChartItem } from './ChartItemCom';

import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

const ResponsiveGridLayout = WidthProvider(Responsive);

type ChartDisplayPreview = {
  values: AutoCompleteItem[];
  layouts: Layouts;
  generalFilter: {
    memberIds: string[];
    cubeAttributeId: string;
    insightViewAttributeId?: string;
    accumulationType: string;
  };
};
const rowsPerBreakpoint = {
  lg: 39,
  md: 41,
  sm: 40,
  xs: 4,
  xxs: 2,
};
export function ChartDisplayLayout(props: Readonly<ChartDisplayPreview>) {
  const { values, layouts: chartLayouts, generalFilter } = props;
  const [layouts, setLayouts] = useState<Layouts>({ lg: [] });
  const [charts, setCharts] = useState<AutoCompleteItem[]>([]);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [rowHeight, setRowHeight] = useState(30);

  const breakpoints = { lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 };
  const cols = { lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 };

  // We'll track the current breakpoint
  const [currentBreakpoint, setCurrentBreakpoint] = useState('lg');

  const updateRowHeight = useCallback(() => {
    if (!containerRef.current) return;
    const { offsetHeight } = containerRef.current;
    // @ts-ignore
    const totalRows = rowsPerBreakpoint[currentBreakpoint] || 10;
    const newRowHeight = Math.max(1, Math.floor(offsetHeight / totalRows));
    setRowHeight(newRowHeight);
  }, [currentBreakpoint]);

  const debouncedUpdateRowHeight = useMemo(() => debounce(updateRowHeight, 250), [updateRowHeight]);
  useEffect(() => {
    window.addEventListener('resize', debouncedUpdateRowHeight);
    // Fire it once on mount
    debouncedUpdateRowHeight();

    return () => {
      window.removeEventListener('resize', debouncedUpdateRowHeight);
      // Also remember to cancel any pending calls when unmounting
      debouncedUpdateRowHeight.cancel?.();
    };
  }, [debouncedUpdateRowHeight]);

  const handleBreakpointChange = (bp: string, cols: number) => {
    setCurrentBreakpoint(bp);
  };

  useEffect(() => {
    setLayouts(chartLayouts);
    setCharts(values);
  }, [values, chartLayouts]);

  return (
    <Stack height='100%' direction='row' px={2} py={1} overflow='hidden' gap={2} ref={containerRef}>
      <Stack width='100%'>
        {charts.length > 0 && (
          <ResponsiveGridLayout
            className='layout'
            style={{
              maxHeight: '75vh',
              width: '100%',
              borderRadius: 3,
            }}
            layouts={layouts}
            breakpoints={breakpoints}
            cols={cols}
            onBreakpointChange={handleBreakpointChange}
            isResizable={false}
            isDraggable={false}
            isDroppable={false}
            rowHeight={rowHeight}
          >
            {charts.map((item, idx) => (
              <div key={item.label}>
                <ChartItem chartItemId={item.value?.toString()} key={item.label} {...generalFilter} />
              </div>
            ))}
          </ResponsiveGridLayout>
        )}
      </Stack>
    </Stack>
  );
}
