// @ts-nocheck
import qs from 'query-string';
import _ from 'lodash';
import { AxiosResponse } from 'axios';
import { useQuery } from 'react-query';
import axios from 'libs/Axios';

import { AxiosDefaultErrorEntity } from 'types/api/Common/ErrorTypes';
import {
  RequestCubeInsightlView,
  ResponseCubeInisghtView,
  ColumnMapItem,
} from 'types/api/Tenant/AROCube/CubeInisghtViewTypes';
import { AutoCompleteItem } from 'types/api/Common/AutoCompleteTypes';
import { getSortedKTreeId, mapKTreeMemberWithId, mapKTreeMemberWithParent } from 'utils/Object';
import { KTreeTableItem } from 'types/api/Tenant/AROKMS/KTreeDataTypes';
import { Data } from 'types/api/Tenant/AROCube/CubeDataEntryTypes';
import { useSearchParams } from 'react-router-dom';

export const CUBE_INSIGHT_VIEW_QUERY_KEY = {
  CUBE_INSIGHT_VIEW_DATA: 'CUBE_INSIGHT_VIEW_DATA',
  CUBE_INSIGHT_VIEW_DROPDOWN: 'CUBE_INSIGHT_VIEW_DROPDOWN',
  CUBE_INSIGHT_VIEW_ATTRIBUTE_DROPDOWN: 'CUBE_INSIGHT_VIEW_ATTRIBUTE_DROPDOWN',
};

export function useGetCubeInsightViewData(payload: RequestCubeInsightlView, enabled = true) {
  const {
    filterMemberValue,
    insightViewId,
    cubeId,
    selectedXOptionType,
    selectedYOptionType,
    showMemberOnly,
    proxyMemberAccessId,
    measureChartCubeAttributeId,
    refChartId,
    refChartMemberIds,
    refChartAccumulationType,
  } = payload;

  const [searchParams] = useSearchParams();
  const myViewId = searchParams.get('myViewId') || undefined;
  return useQuery<AxiosResponse<ResponseCubeInisghtView>, AxiosDefaultErrorEntity>(
    [CUBE_INSIGHT_VIEW_QUERY_KEY.CUBE_INSIGHT_VIEW_DATA, payload],
    () =>
      axios
        .get(
          `/api/v1/tenant/insight-view/display/${insightViewId}/${filterMemberValue}?${qs.stringify({
            cubeId,
            xDimensionType: selectedXOptionType,
            yDimensionType: selectedYOptionType,
            showMemberOnly,
            proxyMemberAccessId,
            myViewId,
            measureChartCubeAttributeId,
            refChartId,
            refChartMemberIds,
            accumulationRule: refChartAccumulationType,
          })}`
        )
        .then((res: AxiosResponse<ResponseCubeInisghtView>) => {
          if (selectedYOptionType === 'MAIN_MEMBER' && !showMemberOnly) {
            const allMembersAndParents = res.data.memberColumns.map((item: KTreeTableItem) => item.member);
            const mapAttributeWithParent = mapKTreeMemberWithParent(res.data.memberColumns);
            const mapKTreeMemberWithID = mapKTreeMemberWithId(res.data.memberColumns);
            const sortedKTreeIds = getSortedKTreeId(allMembersAndParents, mapAttributeWithParent, mapKTreeMemberWithID);
            const columnMap = _.cloneDeep(res.data.columnMap);
            const unorderData = _.cloneDeep(res.data.data);
            const orderedData: Data = {};

            let orderedMap: Map<String, ColumnMapItem> = new Map();
            sortedKTreeIds.forEach((key) => {
              // @ts-ignore
              if (columnMap[key]) {
                // @ts-ignore
                orderedMap.set(key, columnMap[key]);
              }
              if (unorderData[key]) {
                orderedData[key] = unorderData[key];
              }
            });

            const modifedResponse: AxiosResponse<ResponseCubeInisghtView> = {
              ...res,
              data: {
                ...res.data,
                data: orderedData,
              },
            };
            return modifedResponse;
          }

          return res;
        }),
    {
      enabled: !!insightViewId && !!filterMemberValue && !!cubeId && enabled,
    }
  );
}

export function useGetCubeInsightViewDropdown(cubeId: string) {
  return useQuery<AxiosResponse<AutoCompleteItem[]>, AxiosDefaultErrorEntity>(
    [CUBE_INSIGHT_VIEW_QUERY_KEY.CUBE_INSIGHT_VIEW_DROPDOWN, cubeId],
    () => axios.get(`/api/v1/tenant/insight-view/${cubeId}`).then((res) => res),
    {
      enabled: !!cubeId,
    }
  );
}

export function useGetInsightViewCubeAttributesDropdown(cubeId?: string | null) {
  return useQuery<AxiosResponse<AutoCompleteItem[]>, AxiosDefaultErrorEntity>(
    [CUBE_INSIGHT_VIEW_QUERY_KEY.CUBE_INSIGHT_VIEW_ATTRIBUTE_DROPDOWN, cubeId],
    () => axios.get(`/api/v1/tenant/insight-view/cube-attributes-dropdown/${cubeId}`).then((res) => res),
    {
      enabled: !!cubeId,
    }
  );
}
