import { useLayoutEffect, useRef } from 'react';
import * as echarts from 'echarts/core';
import { GaugeChart, LineChart, PieChart } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';
import {
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  DataZoomComponent,
  DataZoomSliderComponent,
} from 'echarts/components';
import { UniversalTransition, LabelLayout } from 'echarts/features';
import { Autocomplete, Box, Stack, TextField } from '@mui/material';
import { AutoCompleteItem } from 'types/api/Common/AutoCompleteTypes';
import { ChartType } from 'constant/ChartViewConstant';
import { ChartDisplay } from 'types/api/Tenant/AROChart/ChartViewTypes';

echarts.use([
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  DataZoomComponent,
  DataZoomSliderComponent,
  GridComponent,
  LegendComponent,
  LineChart,
  LabelLayout,
  PieChart,
  CanvasRenderer,
  GaugeChart,
  UniversalTransition,
]);
const autoCompleteStyle = {
  width: 230,
  '& .MuiAutocomplete-popper': {
    backgroundColor: 'red !imoprtant',
    fontSize: '10px',
  },
};

const textInputStyle = {};
function reviveFunctions(obj: any) {
  if (obj && typeof obj === 'object') {
    for (const key of Object.keys(obj)) {
      const val = obj[key];
      if (typeof val === 'string' && val.trim().startsWith('function')) {
        // eslint-disable-next-line no-eval
        obj[key] = eval(`(${val})`);
      } else if (val && typeof val === 'object') {
        reviveFunctions(val);
      }
    }
  }
}

export const EChartComponent = (props: {
  chartDisplayData: ChartDisplay;
  onFilterChange: (filterName: string, item: AutoCompleteItem) => void;
  onLineChartDataPointClick?: (timePeriodName: string) => void;
}) => {
  const { chartDisplayData, onFilterChange } = props;

  const { chartViewData: chartConfig, chartViewItem, chartViewItemFilters } = chartDisplayData;
  const { chartType } = chartViewItem;

  const chartRef = useRef(null);

  useLayoutEffect(() => {
    if (chartConfig && chartRef.current) {
      const { clientWidth, clientHeight } = chartRef.current;
      if (clientWidth > 0 && clientHeight > 0) {
        const myChart = echarts.init(chartRef.current);
        const option = JSON.parse(chartConfig);
        if (myChart && option) {
          reviveFunctions(option);

          myChart.setOption(option);
          const resizeObserver = new ResizeObserver(() => {
            myChart.resize();
          });

          myChart.on('click', (params) => {
            if (chartType === ChartType.LINE && props.onLineChartDataPointClick) {
              props.onLineChartDataPointClick(params.name);
            }
          });

          resizeObserver.observe(chartRef.current);
          return () => {
            resizeObserver.disconnect();
            myChart.dispose();
          };
        }
      }
    }
    // eslint-disable-next-line
  }, [chartConfig, chartRef.current]);

  if (chartType === ChartType.GAUGE || chartType === ChartType.PIE) {
    return (
      <Stack
        flexDirection='column'
        spacing={2}
        sx={{ width: '100%', height: '100%' }}
        alignItems='center'
        justifyContent='center'
      >
        <Box sx={{ width: { xs: '100%', md: '100%' }, height: '100%' }} ref={chartRef} />
        <Stack
          sx={{ width: { xs: '100%', md: '80%' }, pb: 1.5, flexDirection: 'row', justifyContent: 'center', gap: 1.5 }}
        >
          {chartViewItemFilters?.map((chartFilter) => (
            <Autocomplete
              onChange={(event, value) => {
                if (value) {
                  onFilterChange(chartFilter.name, value);
                }
              }}
              clearIcon={null}
              key={chartFilter.label}
              isOptionEqualToValue={(option: AutoCompleteItem, value: AutoCompleteItem) => option.value === value.value}
              size='small'
              value={chartFilter.selectedFilter}
              getOptionLabel={(option: AutoCompleteItem) => option.label}
              options={chartFilter.options || []}
              renderOption={(props, option) => (
                <Box component='li' sx={{ '& > span': { fontSize: '14px', mr: 1, flexShrink: 0 } }} {...props}>
                  <span>{option.label}</span>
                </Box>
              )}
              sx={{
                ...autoCompleteStyle,
                width: '100%',
              }}
              renderInput={(params) => (
                <Stack direction='row' justifyContent='space-between' alignItems='center'>
                  <TextField
                    {...params}
                    sx={textInputStyle}
                    label={chartFilter.label}
                    placeholder={chartFilter.label}
                    name={chartFilter.name}
                  />
                </Stack>
              )}
            />
          ))}
        </Stack>
      </Stack>
    );
  }

  return <Stack ref={chartRef} style={{ width: '100%', height: '100%' }}></Stack>;
};
