import * as Yup from 'yup';
import { useEffect, useMemo, useState } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import ActivityIndicator from 'components/ActivityIndicatorComponent';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import InfoIcon from '@mui/icons-material/Info';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import VpnKeyOutlinedIcon from '@mui/icons-material/VpnKeyOutlined';
import ButtonLoading from '@mui/lab/LoadingButton';
import {
  useGetTenantConfigData,
  useGetTenantUsers,
  useSaveMyViewDashboard,
} from 'services/v1/Tenant/ConfigurationService';
import {
  DataGridPro,
  GridColDef,
  GridRenderCellParams,
  GridRowModel,
  GridRowOrderChangeParams,
  GridRowParams,
} from '@mui/x-data-grid-pro';
import { Autocomplete, Button, IconButton, TextField, Tooltip } from '@mui/material';
import { ModalSelectMyViewItem } from './ModalSelectMyViewItem';
import {
  MyViewDashboardDefinitionItem,
  MyViewItemRequest,
  MyViewWorkFlowItem,
  PeopleMyViewItemConfig,
  RequestSaveMyViewAdditionalConfig,
  RequestSaveMyViewDashboard,
} from 'types/api/Tenant/ConfigurationTypes';
import { BackToListButton } from 'components/ButtonComponent/ButtonBackToList';
import { ModalMyViewAdditionalConfig } from './ModalMyViewAdditionalConfig';
import { NAVIGATION_COMPONENT_TYPE } from 'constant/NavigationConstant';
import { useFormik } from 'formik';
import { ChevronRightOutlined } from '@mui/icons-material';
import { userSelectionDataGridStyle } from 'pages/TenantMaster/MasterTable/TenantTable/AddTenantPage';
import { ViewDetailsMode } from '.';
import { useGetSolutionDefinitionDropdown } from 'services/v1/SystemTenant/AROKMS/SolutionDefinitionService';
import { AutoCompleteItem } from 'types/api/Common/AutoCompleteTypes';

function updateRowPosition(oldIndex: number, newIndex: number, rows: Array<GridRowModel<PeopleMyViewItemConfigState>>) {
  const rowsClone = [...rows];
  const [row] = rowsClone.splice(oldIndex, 1);
  rowsClone.splice(newIndex, 0, row);

  for (let i = 0; i < rowsClone.length; i++) {
    rowsClone[i].colIndexOrder = i + 1;
  }

  return rowsClone;
}

interface MyViewDashboardDetailComponentProps {
  onClose?: () => void;
  selectedDashboard: MyViewDashboardDefinitionItem | null;
  detailMode: ViewDetailsMode | null;
}

const autocompleteStyles = {
  width: '55%',
  '& .MuiOutlinedInput-root': {
    padding: '1px 2px',
  },
  '& .MuiAutocomplete-popper': {
    fontSize: '10px',
  },
};
const initialModalMyViewConfigValue = {
  show: false,
  defaultXDimension: null,
  defaultYDimension: null,
  defaultDimensionFilter: null,
};

const textInputStyles = {
  '& .MuiOutlinedInput': {
    padding: '1px 2px',
  },
};

interface FormTableAnalyticParameter {
  dashboardName: string | null;
  solutionGroup: AutoCompleteItem | null;
  userIds: string[];
  myViewItems: MyViewItemRequest[];
}

type PeopleMyViewItemConfigState = Omit<PeopleMyViewItemConfig, 'id'> & {
  id: string | null;
  ktreeDefinitionId: string | null;
  ktreeName?: string | null;
};

export const formikValidationSchema = Yup.object().shape({
  dashboardName: Yup.string().required('Dashboard Name is required').nullable(),
  solutionGroup: Yup.object().required('Solution is required').nullable(),
});

const formikInitialValues: FormTableAnalyticParameter = {
  dashboardName: null,
  solutionGroup: null,
  userIds: [],
  myViewItems: [],
};

export const MyViewDashboardDetail = (props: MyViewDashboardDetailComponentProps) => {
  const { onClose, selectedDashboard, detailMode } = props;

  const { isLoading } = useGetTenantConfigData({ refetchInterval: false });
  const [showModalComponentList, setShowModalComponentList] = useState(false);
  const [modalMyViewConfig, setModalMyViewConfig] = useState(initialModalMyViewConfigValue);
  const [selectedUserViewConfig, setSelectedUserViewConfig] = useState<PeopleMyViewItemConfig | null>(null);
  const [rowData, setRowData] = useState<PeopleMyViewItemConfigState[]>([]);
  const { data: tenantUserList, isLoading: isLoadingUsers } = useGetTenantUsers();
  const { data: solutionGroupDropdown } = useGetSolutionDefinitionDropdown();
  const formik = useFormik<FormTableAnalyticParameter>({
    initialValues: formikInitialValues,
    validationSchema: formikValidationSchema,
    onSubmit: (values, formikHelper) => {
      formikHelper.resetForm();
    },
  });

  const { mutate: saveMyViewDashboard, isLoading: isSubmitting } = useSaveMyViewDashboard();

  const handleOnClose = () => {
    onClose?.();
    formik.resetForm();
  };

  const handleOnSubmitDefaultLoginView = (myViewId: string) => {
    setRowData((prevData) => {
      return prevData.map((row) => {
        if (row.id === myViewId) {
          return {
            ...row,
            defaultLoginView: true,
          };
        }
        return {
          ...row,
          defaultLoginView: false,
        };
      });
    });
  };

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'componentName',
        headerName: 'Component Name',
        renderCell: (params: GridRenderCellParams) => {
          if (params.row.defaultLoginView) {
            return <div>{params.row.componentName} (Login View)</div>;
          }
          return <div>{params.row.componentName}</div>;
        },

        width: 250,
      },
      {
        field: 'componentMember',
        headerName: 'Member Filter',

        renderCell: (params: GridRenderCellParams) => {
          if (!params.row.componentMember) return null;
          return (
            <Stack direction='row' alignItems='center'>
              <div>{params.row.componentMember}</div>
            </Stack>
          );
        },
        width: 200,
      },
      {
        field: 'defaultDimensionFilter',
        headerName: 'Dimension Filter',

        renderCell: (params: GridRenderCellParams) => {
          if (params.row.componentType !== NAVIGATION_COMPONENT_TYPE.INSIGHT_VIEW.toString()) return 'N/A';

          return (
            <Stack direction='row' alignItems='center'>
              <div>{params.row.defaultYDimension ? 'Configured' : 'Default'}</div>
            </Stack>
          );
        },
        width: 150,
      },
      {
        field: 'additionalWorkflows',
        headerName: 'Drill Down Flow',

        renderCell: (params: GridRenderCellParams) => {
          if (params.row.componentType !== NAVIGATION_COMPONENT_TYPE.INSIGHT_VIEW.toString()) return 'N/A';
          const additionalWorkFlows = params.row.additionalWorkFlows;
          const values = `${additionalWorkFlows?.length} flow(s)`;
          const tooltipValues = additionalWorkFlows?.map((item: MyViewWorkFlowItem) => (
            <div key={item.componentName}>{item.componentName}</div>
          ));
          return (
            <Stack direction='row' alignItems='center'>
              <div>{values}</div>
              <Tooltip title={tooltipValues}>
                <IconButton size='small'>
                  <InfoIcon fontSize='inherit' color='primary' />
                </IconButton>
              </Tooltip>
            </Stack>
          );
        },
        width: 150,
      },
      {
        field: 'actions',
        type: 'actions',
        headerName: '',
        width: 200,
        getActions: (params: GridRowParams) => {
          const defaultLoginViewButton = (
            <Tooltip title='Set as Default Login View'>
              <IconButton
                onClick={() => {
                  handleOnSubmitDefaultLoginView(params.row.id);
                }}
                size='medium'
              >
                <VpnKeyOutlinedIcon fontSize='inherit' />
              </IconButton>
            </Tooltip>
          );
          const additionalConfigButton = (
            <Tooltip title='Additional Configuration'>
              <IconButton
                onClick={() => {
                  setModalMyViewConfig({
                    show: true,
                    defaultXDimension: params.row.defaultXDimension,
                    defaultYDimension: params.row.defaultYDimension,
                    defaultDimensionFilter: params.row.defaultDimensionFilter,
                  });
                  setSelectedUserViewConfig(params.row as PeopleMyViewItemConfig);
                }}
                size='medium'
              >
                <SettingsOutlinedIcon fontSize='inherit' />
              </IconButton>
            </Tooltip>
          );
          if (params.row.defaultLoginView) {
            return [additionalConfigButton];
          }
          return [additionalConfigButton, defaultLoginViewButton];
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const userColumns: GridColDef[] = useMemo(
    () => [
      {
        field: 'fullName',
        headerName: 'Full Name',

        width: 250,
      },
      {
        field: 'role',
        headerName: 'Role',
        width: 200,
      },
    ],
    []
  );

  const selectedItemIds: Set<string> = useMemo(() => {
    const selectedItemIds =
      rowData.map((item) => {
        if (item.subjectId) {
          return `${item.componentId}#${item.subjectId}`;
        }
        return item.componentId;
      }) || [];
    return new Set(selectedItemIds);
  }, [rowData]);

  const handleAddComponent = () => {
    setShowModalComponentList(true);
  };

  const handleOnAddMyViewComponent = (data: MyViewItemRequest[]) => {
    const newRecordInsertionId = `NEW-${new Date()}`;
    const updateRow: PeopleMyViewItemConfigState[] = data.map((item, index) => {
      const isNew = selectedItemIds.has(item.id);

      return {
        id: item.id ? item.id : newRecordInsertionId,
        componentId: item.id,
        componentName: item.componentName,
        componentType: item.componentType,
        componentMember: null,
        componentMemberId: null,
        subjectId: item.subjectId ?? null,
        defaultLoginView: false,
        additionalWorkFlows: [],
        defaultXDimension: null,
        defaultYDimension: null,
        defaultDimensionFilter: null,
        ktreeDefinitionId: item.ktreeDefinitionId,
        ktreeName: item.ktreeName || undefined,
        cubeDefinitionId: item.cubeDefinitionId || undefined,
        colIndexOrder: isNew ? index + 1 : item.colIndexOrder,
        currentPeriodOffset: item.currentPeriodOffset,
        defaultMeasurement: item.defaultMeasurement,
      };
    });

    setRowData(updateRow);
  };

  const handleOnAddMyViewConfig = (data: RequestSaveMyViewAdditionalConfig) => {
    setRowData((prevRowData) => {
      return prevRowData.map((row) => {
        if (row.id === data.myViewId) {
          return {
            ...row,
            defaultXDimension: data.defaultXDimension,
            defaultYDimension: data.defaultYDimension,
            additionalWorkFlows: [
              {
                componentId: data.additionalWorkFlows[0],
                componentType: '',
                componentName: '',
                indexOrder: 1,
                id: null,
              },
            ],
            defaultDimensionFilter: data.defaultFilterMember,
            componentMemberId: data.memberId,
            componentMember: data.memberName,
            currentPeriodOffset: data.currentPeriodOffset,
            defaultMeasurement: data.defaultMeasurement,
          };
        }
        return row;
      });
    });
  };

  const handleRowOrderChange = async (params: GridRowOrderChangeParams) => {
    const newRows = updateRowPosition(params.oldIndex, params.targetIndex, rowData);
    setRowData(newRows);
  };

  const handleOnSaveDashboard = () => {
    if (formik.values.dashboardName && formik.values.solutionGroup?.value) {
      const payload: RequestSaveMyViewDashboard = {
        id: selectedDashboard?.id ? selectedDashboard.id : null,
        dashboardName: formik.values.dashboardName,
        solutionDefinitionId: formik.values.solutionGroup?.value.toString(),
        myViewItems: rowData.map((item) => ({
          id: item.componentId,
          componentName: item.componentName,
          componentType: item.componentType,
          subjectId: item.subjectId,
          defaultXDimension: item.defaultXDimension,
          defaultYDimension: item.defaultYDimension,
          defaultFilterMember: item.defaultDimensionFilter,
          memberId: item.componentMemberId,
          additionalWorkFlows: item.additionalWorkFlows.map((item) => item.componentId).filter((item) => Boolean(item)),
          additionalConfig: null,
          ktreeDefinitionId: item.ktreeDefinitionId,
          ktreeName: item.ktreeName || null,
          cubeDefinitionId: item.cubeDefinitionId || null,
          colIndexOrder: item.colIndexOrder,
          currentPeriodOffset: item.currentPeriodOffset,
          defaultMeasurement: item.defaultMeasurement,
        })),
        userIds: formik.values.userIds,
      };

      saveMyViewDashboard(payload, {
        onSuccess: () => {
          onClose?.();
          formik.resetForm();
        },
      });
    }
  };

  useEffect(() => {
    if (selectedDashboard) {
      formik.setValues({
        dashboardName: selectedDashboard.dashboardName,
        userIds: selectedDashboard.users.map((user) => user.id),
        // @ts-ignore
        myViewItems: selectedDashboard.myViews,
        solutionGroup: selectedDashboard.solutionDefinition,
      });

      setRowData(selectedDashboard.myViews);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDashboard]);

  const isLoadingData = isLoading;

  return (
    <Box sx={{ bgcolor: '#fff', px: 1, py: 2, width: '100%', overflow: 'auto' }}>
      <Stack justifyContent='space-between'>
        <Stack mb={2} width='100%'>
          <Stack direction='row' alignItems='center' gap={1}>
            <Typography variant='h6' color='text.primary' fontWeight='bold'>
              {detailMode === ViewDetailsMode.INSERT ? 'Add Dashboard' : 'Edit Dashboard'}
            </Typography>
            {detailMode === ViewDetailsMode.EDIT && (
              <>
                <ChevronRightOutlined />
                <Typography variant='h6' color='text.primary' fontWeight='bold'>
                  {selectedDashboard?.dashboardName}
                </Typography>
              </>
            )}
          </Stack>
          <Divider sx={{ mt: 1 }} />
        </Stack>
        <Stack sx={{ maxHeight: 420, pr: 2, pl: 1, overflow: 'auto' }}>
          <Stack width={150} alignItems='flex-start'>
            <BackToListButton title='Return to list' onClick={handleOnClose} />
          </Stack>

          {isLoading ? (
            <Stack direction='column' justifyContent='center'>
              <ActivityIndicator />
            </Stack>
          ) : (
            <Stack direction='column' display='flex'>
              <Stack width='100%' mb={2}>
                <Stack width='50%' mb={1}>
                  <Typography variant='input-label' fontWeight={800}>
                    Dashboard Name
                  </Typography>
                </Stack>
                <Stack width='60%'>
                  <TextField
                    sx={textInputStyles}
                    name='dashboardName'
                    hiddenLabel
                    value={formik.values.dashboardName}
                    onChange={(e) => {
                      formik.handleChange(e);
                    }}
                    onBlur={formik.handleBlur}
                    error={Boolean(formik.touched.dashboardName && formik.errors.dashboardName)}
                    helperText={formik.touched.dashboardName && formik.errors.dashboardName}
                    variant='outlined'
                    size='small'
                    placeholder='e.g. 3'
                  />
                </Stack>
              </Stack>
              <Stack width='100%' mb={2}>
                <Stack width='50%' mb={1}>
                  <Typography variant='input-label' fontWeight={800}>
                    Solution Group
                  </Typography>
                </Stack>
                <Stack width='60%'>
                  <Autocomplete
                    options={solutionGroupDropdown?.data || []}
                    value={formik.values.solutionGroup}
                    sx={{ ...autocompleteStyles, width: '100%' }}
                    onBlur={formik.handleBlur}
                    onChange={(event, newValue) => {
                      formik.setFieldValue('solutionGroup', newValue);
                    }}
                    clearIcon={null}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder='Select Solution Group...'
                        name='solutionGroup'
                        error={Boolean(formik.touched.solutionGroup && formik.errors.solutionGroup)}
                        helperText={formik.touched.solutionGroup && formik.errors.solutionGroup}
                      />
                    )}
                  />
                </Stack>
              </Stack>
              <Stack my={1}>
                <Typography variant='input-label' fontWeight={800}>
                  Assign myView items
                </Typography>
                <Typography variant='body2' color='text.secondary'>
                  myView items are the items that your user can see on their myView page. This allows your user to
                  navigate to the page that they need to access quickly. You can assign multiple items to a user.
                </Typography>
              </Stack>
              <Stack width={180} my={1}>
                <Button
                  variant='secondary-table-panel'
                  startIcon={<AddCircleOutlineOutlinedIcon />}
                  onClick={handleAddComponent}
                  disabled={isLoadingData}
                >
                  Add or Remove
                </Button>
              </Stack>
              <Stack mt={1} height={300} width='100%'>
                <DataGridPro
                  key='COMPONENTS'
                  autoHeight={false}
                  loading={isLoadingData}
                  pagination
                  disableMultipleColumnsFiltering
                  paginationMode='client'
                  density='compact'
                  getRowId={(row) => (row.id ? row.id : row.componentId)}
                  rowCount={rowData.length ?? 0}
                  rows={rowData || []}
                  columns={columns}
                  rowReordering
                  onRowOrderChange={handleRowOrderChange}
                  filterMode='client'
                  checkboxSelection={false}
                />
              </Stack>
              <Stack my={1}>
                <Typography variant='input-label' fontWeight={800}>
                  Assign users
                </Typography>
                <Typography variant='body2' color='text.secondary'>
                  myView items are the items that your user can see on their myView page. This allows your user to
                  navigate to the page that they need to access quickly. You can assign multiple items to a user.
                </Typography>
              </Stack>
              <Stack mt={1} height={300} width='100%'>
                {!isLoadingUsers && (
                  <DataGridPro
                    autoHeight={false}
                    key='MUI_USERS'
                    loading={isLoadingUsers}
                    pagination
                    sx={userSelectionDataGridStyle}
                    density='compact'
                    rowCount={tenantUserList?.data?.length ?? 0}
                    rows={tenantUserList?.data || []}
                    isRowSelectable={(params) => params.row.dashboard == null}
                    getRowClassName={(params) => (params.row.dashboard != null ? 'insight-view-cell-unsorted' : '')}
                    columns={userColumns}
                    checkboxSelection
                    hideFooter
                    selectionModel={formik.values.userIds}
                    onSelectionModelChange={(newSelection) => {
                      if (tenantUserList?.data) {
                        formik.setFieldValue('userIds', newSelection);
                      }
                    }}
                  />
                )}
              </Stack>
            </Stack>
          )}
          <Stack alignSelf='flex-end' my={1}>
            <Stack width={180}>
              <ButtonLoading
                variant='main-table-panel'
                onClick={handleOnSaveDashboard}
                disabled={isLoadingData || !formikValidationSchema.isValidSync(formik.values) || rowData.length === 0}
                loading={isSubmitting}
              >
                Save
              </ButtonLoading>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <ModalSelectMyViewItem
        visible={showModalComponentList}
        onClose={() => setShowModalComponentList(false)}
        selectedDashboard={selectedDashboard}
        onSave={handleOnAddMyViewComponent}
        selectedItemIds={selectedItemIds}
      />

      <ModalMyViewAdditionalConfig
        visible={modalMyViewConfig.show}
        defaultXDimensionTypeId={modalMyViewConfig.defaultXDimension}
        defaultYDimensionTypeId={modalMyViewConfig.defaultYDimension}
        defaultFilterMemberId={modalMyViewConfig.defaultDimensionFilter}
        onClose={() => setModalMyViewConfig(initialModalMyViewConfigValue)}
        onSubmit={handleOnAddMyViewConfig}
        selectedView={selectedUserViewConfig}
      />
    </Box>
  );
};
