import Stack from '@mui/material/Stack';
import { IconButton, Tooltip } from '@mui/material';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { AnimatedPage } from 'components/AnimatedPage';
import { CustomDataTable } from 'components/DatatableComponent';
import { useGetCubeInsightViewData } from 'services/v1/Tenant/AROCube/CubeInsightViewService';
import { useEffect, useMemo, useRef, useState } from 'react';
import { CUBE_PERCENTAGE_TYPE } from 'constant/CubeConstant';
import { formatCubeNumber } from 'utils/NumberUtils';
import { jsonToGridData } from '../CubeDataEntryPage/utils/CubeDataEntryUtils';
import { GRID_TREE_DATA_GROUPING_FIELD } from '@mui/x-data-grid-pro/hooks/features/treeData/gridTreeDataGroupColDef';
import {
  DataGridProProps,
  GridCellParams,
  GridColumns,
  GridColumnGroupingModel,
  GridRenderCellParams,
  GridSortModel,
  isLeaf,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import { CustomGridTreeDataGroupingCell } from '../CubeDataEntryPage/components/CustomGridTreeDataGroupingCell';
import ActivityIndicator from 'components/ActivityIndicatorComponent';
import { ErrorBoxComponent } from 'components/Error/ErrorBoxComponent';
import { CubeDataEntryAttribute } from 'types/api/Tenant/AROCube/CubeDataEntryTypes';
import { InsightViewType, MAP_COLOR_CODE_WITH_CLASS_NAME } from 'constant/ViewConstant';
import { CubeInsightViewPanel } from './CubeInsightViewPanel';
import {
  InsightViewDimensionalOptionValue,
  RequestCubeInsightlView,
  ResponseCubeInisghtView,
} from 'types/api/Tenant/AROCube/CubeInisghtViewTypes';
import {
  generateBreadCumbItemsV2,
  TenantHomeDashboardNavigationBar,
} from 'components/LayoutComponent/VerticalLayout/HeaderBar';
import { useParams, useSearchParams } from 'react-router-dom';
import { NAVIGATION_COMPONENT_TYPE } from 'constant/NavigationConstant';
import { useGetBreadcumbItems } from 'services/v1/Common/NavBarMenuService';
import { getPageAnimationByRefNavigation } from 'utils/Animation';
import { useGetInsightViewDefinitionDetails } from 'services/v1/SystemTenant/AROView/InsightViewDefinitionService';
import { generateColumnNames, handleExport } from 'utils/ExportUtils';

const insightViewStyle = {
  minHeight: 400,
  '& .MuiDataGrid-columnHeaders': {
    fontWeight: 'bold',
    fontSize: '12px',
    color: '#000000',
    borderBottomColor: '#c9c9c9',
  },
  '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
    borderRight: `1px solid #c9c9c9`,
  },
};

function getInsightViewCellClassName(params: GridCellParams, cubeAttribute: CubeDataEntryAttribute) {
  const { row, field } = params;
  const cellValue = row?.[field] ?? null;
  if (cubeAttribute.lessThanValue != null && cellValue < cubeAttribute.lessThanValue)
    return MAP_COLOR_CODE_WITH_CLASS_NAME.get(cubeAttribute.lessThanColor);
  if (cubeAttribute.greaterThanValue != null && cellValue > cubeAttribute.greaterThanValue)
    return MAP_COLOR_CODE_WITH_CLASS_NAME.get(cubeAttribute.greaterThanColor);
  return '';
}

function getHeaderName(data: ResponseCubeInisghtView, viewAttributeId: string) {
  return data.columnMap[viewAttributeId]?.timePeriod ?? data.columnMap[viewAttributeId]?.displayName ?? '';
}

function isHasCellColorDefinition(cubeAttribute: CubeDataEntryAttribute) {
  return cubeAttribute.lessThanValue != null || cubeAttribute.greaterThanValue != null;
}

const getColumns = (
  columnGroupings: GridColumnGroupingModel,
  insightViewData: ResponseCubeInisghtView,
  getHeaderName: (data: ResponseCubeInisghtView, viewAttributeId: string) => string,
  mapCubeAttributeNameWithObject: Map<string, CubeDataEntryAttribute>,
  percentageRenderAttributes: string[],
  decipalPlacesRenderAttributes: { [key: string]: number },
  yDimensionType: string | undefined,
  tableSortModel?: GridSortModel,
  exlcudeColumnsFromCellColor?: Set<string>
): GridColumns => {
  let minWidth = 130;
  let maxWidth = 300;

  if (yDimensionType === 'MAIN_MEMBER') {
    minWidth = 180;
    maxWidth = 430;
  }

  const isYDimensionInsightColumn = yDimensionType === 'INSIGHT_COLUMNS_MEMBER';
  // Flatten the groupings to get an ordered list of field ids
  const orderedFieldIds = isYDimensionInsightColumn
    ? columnGroupings.flatMap((group) => group.children.flatMap((child) => (isLeaf(child) ? [child.field] : [])))
    : Object.keys(insightViewData.data);

  // Map the ordered field ids to their column definitions
  return orderedFieldIds.map((fieldId) => {
    const headerName = getHeaderName(insightViewData, fieldId);
    return {
      field: fieldId,
      headerName,
      type: 'number',
      editable: false,
      minWidth,
      maxWidth,
      headerAlign: 'center',
      align: 'center',
      cellClassName: (params: GridCellParams) => {
        let className = '';
        const isSortActiveAndCurrentColumnNotOnSortModel =
          tableSortModel && tableSortModel.length > 0 && tableSortModel[0].field !== params.field;
        if (isSortActiveAndCurrentColumnNotOnSortModel) className = 'insight-view-cell-unsorted ';
        const attributeName = params.id.toString().split('#')[0];
        const cubeAttribute: CubeDataEntryAttribute | undefined = mapCubeAttributeNameWithObject.get(attributeName);
        if (cubeAttribute) {
          const isCellExcludedFromColor = exlcudeColumnsFromCellColor?.has(params.field);
          const isHasCellColorRule = isHasCellColorDefinition(cubeAttribute);
          if (isHasCellColorRule && !isCellExcludedFromColor) {
            className += getInsightViewCellClassName(params, cubeAttribute);
          }
        }
        return className;
      },
      renderCell: (params: GridRenderCellParams) => {
        const attributeName = params.id.toString().split('#')[0];
        const decimalPlaces = decipalPlacesRenderAttributes?.[attributeName] || 0;

        const isPercentageColumn = params.field.includes('_percentage');
        if (isPercentageColumn) {
          return params?.value && formatCubeNumber(params?.value, 2, true);
        }
        if (percentageRenderAttributes?.includes(attributeName)) {
          return params?.value && formatCubeNumber(params?.value, decimalPlaces, true);
        }
        return params?.value && formatCubeNumber(params?.value, decimalPlaces);
      },
    };
  });
};

export default function CubeInsightViewPage() {
  const { cubeId, insightId } = useParams<{ cubeId: string; insightId: string }>();
  const apiRef = useGridApiRef();
  const cubeSettingPanelRef = useRef<{
    isLoadingOptionsData: boolean;
  }>(null);

  const [searchParams] = useSearchParams();
  const navigationRef = searchParams.get('ref');
  const refChartId = searchParams.get('refChartId') || undefined;
  const proxyMainMemberId = searchParams.get('proxyMainMemberId') || undefined;

  const [optionValue, setOptionValue] = useState<{
    xDimensionType: string | undefined;
    yDimensionType: string | undefined;
    memberId: string | undefined;
    showMemberOnly?: boolean | null;
    measureChartCubeAttributeId?: string | null;
    memberIds?: string[] | null;
    refChartAccumulationType?: string | null;
  }>({
    xDimensionType: undefined,
    yDimensionType: undefined,
    memberId: undefined,
    showMemberOnly: false,
  });

  const [tableSortModel, setTableSortModel] = useState<GridSortModel | undefined>(undefined);

  const insightViewDimensionRequest: RequestCubeInsightlView = {
    insightViewId: insightId,
    filterMemberValue: optionValue.memberId ?? null,
    selectedXOptionType: optionValue.xDimensionType ?? null,
    selectedYOptionType: optionValue.yDimensionType ?? null,
    showMemberOnly: optionValue.showMemberOnly,
    selectedXOptionId: null,
    selectedYOptionId: null,
    cubeId: cubeId,
    proxyMemberAccessId: proxyMainMemberId,
    measureChartCubeAttributeId: optionValue?.measureChartCubeAttributeId,
    refChartId: refChartId,
    refChartMemberIds: optionValue.memberIds,
    refChartAccumulationType: optionValue.refChartAccumulationType,
  };

  const { data: insighViewDefinitionDetails, isLoading: isLoadingDetails } =
    useGetInsightViewDefinitionDetails(insightId);

  const {
    data: insightViewData,
    isFetching: isFetchingData,
    isLoading: isDataLoading,
    isSuccess: isDataReady,
    isError: isDataError,
    error: dataError,
  } = useGetCubeInsightViewData(insightViewDimensionRequest, Boolean(insighViewDefinitionDetails?.data?.id));

  const { data: breadCrumbItems } = useGetBreadcumbItems(insightId, NAVIGATION_COMPONENT_TYPE.INSIGHT_VIEW);
  const [rowData, setRowData] = useState<any>([]);

  const groupingColDef: DataGridProProps['groupingColDef'] = useMemo(() => {
    let minWidth = 200;
    let maxWidth = 400;

    if (insightViewData?.data && insightViewData.data?.selectedXOption?.type === 'MAIN_MEMBER') {
      minWidth = 270;
      maxWidth = 430;
    }
    return {
      headerName: '',
      pinnable: true,
      filterable: true,
      leafField: 'name',
      minWidth: minWidth,
      maxWidth: maxWidth,
      cellClassName: 'custom-cube-tree-data-grouping-cell',
      headerClassName: 'custom-cube-tree-data-header',
      renderCell: CustomGridTreeDataGroupingCell,
    };
  }, [insightViewData?.data]);

  const initialValuesSelection = useMemo(() => {
    const initialValues = {
      xDimensionType: {
        label: 'Measurement',
        value: 'MEASURE_ATTRIBUTE',
        type: 'MEASUREMENT_MEMBER',
      },
      yDimensionType: {
        label: 'Insight Column',
        value: 'INSIGHT_COLUMN_MEMBER',
        type: 'INSIGHT_COLUMNS_MEMBER',
      },
      member: null,
      measureChartCubeAttribute: undefined,
    };

    return initialValues;
  }, []);

  const percentageRenderAttributes = useMemo(() => {
    if (insightViewData?.data) {
      return insightViewData?.data.attributes
        .filter((attr) => attr.percentage === CUBE_PERCENTAGE_TYPE.PERCENTAGE)
        .map((attr) => attr.name);
    }
    return [];
  }, [insightViewData?.data]);

  const decipalPlacesRenderAttributes: { [key: string]: number } = useMemo(() => {
    if (insightViewData?.data) {
      return insightViewData?.data.attributes.reduce((acc, attr) => {
        // @ts-ignore
        acc[attr.name] = attr.decimal;
        return acc;
      }, {} as { [key: string]: number });
    }
    return {};
  }, [insightViewData?.data]);

  const mapCubeAttributeNameWithObject: Map<string, CubeDataEntryAttribute> = useMemo(() => {
    const map = new Map();
    if (insightViewData?.data) {
      insightViewData?.data.attributes.forEach((attribute) => {
        map.set(attribute.name, attribute);
      });
    }

    return map;
  }, [insightViewData?.data]);

  const columnGroupingMode: GridColumnGroupingModel = useMemo(() => {
    if (optionValue.yDimensionType !== 'INSIGHT_COLUMNS_MEMBER') return [];

    if (insightViewData?.data?.columnGroupingModel) {
      return insightViewData?.data?.columnGroupingModel.map((item) => ({
        ...item,
        headerAlign: 'center',
        headerClassName: 'font-bold',
      }));
    }
    return [];
  }, [insightViewData?.data?.columnGroupingModel, optionValue.yDimensionType]);

  const columns = useMemo(() => {
    if (!insightViewData?.data) return [];

    const exlcudeColumnsFromCellColor: Set<string> = new Set();
    Object.keys(insightViewData.data.columnMap).forEach((key) => {
      const displayName = insightViewData.data.columnMap[key].displayName;
      if (displayName === 'Var' || displayName === 'Var %') {
        exlcudeColumnsFromCellColor.add(key);
      }
    });
    return getColumns(
      columnGroupingMode,
      insightViewData?.data,
      getHeaderName,
      mapCubeAttributeNameWithObject,
      percentageRenderAttributes,
      decipalPlacesRenderAttributes,
      optionValue.yDimensionType,
      tableSortModel,
      exlcudeColumnsFromCellColor
    );
  }, [
    insightViewData?.data,
    percentageRenderAttributes,
    decipalPlacesRenderAttributes,
    mapCubeAttributeNameWithObject,
    optionValue.yDimensionType,
    columnGroupingMode,
    tableSortModel,
  ]);

  const handleOnOptionChange = (data: InsightViewDimensionalOptionValue) => {
    // if xDimesnionType or yDimensionType is changed, reset the column sort model
    if (
      data.xDimensionType?.type !== optionValue.xDimensionType ||
      data.yDimensionType?.type !== optionValue.yDimensionType ||
      data.member?.value?.toString() !== optionValue.memberId
    ) {
      resetColumnSortModel();
    }

    if (data.members && data.members.length > 0) {
      setOptionValue({
        xDimensionType: data.xDimensionType?.type,
        yDimensionType: data.yDimensionType?.type,
        memberId: data.member?.value?.toString(),
        showMemberOnly: data.showMemberOnly,
        measureChartCubeAttributeId: data?.measureChartCubeAttribute?.value?.toString(),
        memberIds: data.members.map((member) => member.value.toString()),
        refChartAccumulationType: data.refChartAccumulationType?.value?.toString(),
      });
    } else {
      setOptionValue({
        xDimensionType: data.xDimensionType?.type,
        yDimensionType: data.yDimensionType?.type,
        memberId: data.member?.value?.toString(),
        showMemberOnly: data.showMemberOnly,
        measureChartCubeAttributeId: data?.measureChartCubeAttribute?.value?.toString(),
      });
    }
  };
  const handleOnColumnSort = (sortModel: GridSortModel) => {
    setTableSortModel(sortModel);
  };

  const handleOnExportClick = () => {
    const exportConfig = {
      columnNames: generateColumnNames(apiRef),
      keys: generateColumnNames(apiRef),
      sheetName: insightViewData?.data?.measureChartCubeAttribute?.label || 'Sheet1',
      fileName: `${insighViewDefinitionDetails?.data.name}.xlsx`,
    };
    handleExport(apiRef, exportConfig, [
      {
        Filter: `Member: ${insightViewData?.data.memberColumns?.[0]?.member}`,
      },
      {
        Filter: `Attribute: ${insightViewData?.data?.measureChartCubeAttribute?.label}`,
      },
    ]);
  };

  const initialScrollColumnIndex = useMemo(() => {
    if (insightViewData?.data) {
      return insightViewData?.data.todayTimePeriodIndex + 2;
    }
    return 0;
  }, [insightViewData?.data]);

  const resetColumnSortModel = () => {
    setTableSortModel(undefined);
  };

  const breadCrumbs = useMemo(() => {
    return generateBreadCumbItemsV2(breadCrumbItems?.data || []);
  }, [breadCrumbItems?.data]);

  useEffect(() => {
    if (insightViewData?.data) {
      setRowData(jsonToGridData(insightViewData?.data, optionValue.xDimensionType === 'MAIN_MEMBER'));
    }
  }, [insightViewData?.data, optionValue.xDimensionType]);

  useEffect(() => {
    if (initialScrollColumnIndex === 0) return;
    if (apiRef?.current) {
      setTimeout(() => {
        apiRef.current.scrollToIndexes({
          colIndex: initialScrollColumnIndex,
          rowIndex: 0,
        });
      }, 200);
    }
  }, [apiRef, initialScrollColumnIndex]);

  const isLoadingData =
    isLoadingDetails || isDataLoading || (isFetchingData && !navigationRef) || insightViewData === undefined;

  return (
    <TenantHomeDashboardNavigationBar
      breadCrumbs={breadCrumbs}
      containerSx={{ height: '100%', flex: 1, flexDirection: 'column', overflowY: 'auto', display: 'flex' }}
      rootContainerSx={{ height: '95vh' }}
    >
      <AnimatedPage
        key={insightId || 'default-insight'}
        variants={getPageAnimationByRefNavigation(navigationRef)}
        style={{
          height: '100%',
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
        }}
      >
        <CubeInsightViewPanel
          ref={cubeSettingPanelRef}
          insightViewId={insightId}
          cubeDefinitionId={cubeId}
          insightName={insightViewData?.data?.name}
          disabled={isLoadingData}
          initialValues={initialValuesSelection}
          xDimensionOptions={insightViewData?.data?.xoptions ?? []}
          yDimensionOptions={insightViewData?.data?.yoptions ?? []}
          loading={isLoadingData}
          onOptionChange={handleOnOptionChange}
        />
        {insighViewDefinitionDetails?.data?.insightViewType === InsightViewType.CHART &&
          !isLoadingData &&
          isDataReady && (
            <Stack direction='row' justifyContent='space-between' alignSelf='flex-end' width='100%' px={2}>
              <div />
              <Tooltip title='Export Data'>
                <IconButton data-testid='button-export' aria-haspopup='true' onClick={handleOnExportClick}>
                  <FileDownloadOutlinedIcon sx={{ color: '#42BB93' }} />
                </IconButton>
              </Tooltip>
            </Stack>
          )}

        {isLoadingData && (
          <Stack height={550}>
            <ActivityIndicator />
          </Stack>
        )}
        {!isLoadingData && isDataReady && (
          <Stack mb={1.6} width='100%' direction='row' spacing={2.5} alignItems='center' height='100%'>
            <CustomDataTable
              apiRef={apiRef}
              initialState={{
                pinnedColumns: { left: [GRID_TREE_DATA_GROUPING_FIELD] },
                pagination: {
                  page: 0,
                },
                detailPanel: {
                  expandedRowIds: [1],
                },
              }}
              columns={columns}
              rows={rowData}
              columnGroupingModel={columnGroupingMode}
              treeData
              getRowId={(row) => row?.rowid || 0}
              getTreeDataPath={(row) => row.path}
              groupingColDef={groupingColDef}
              getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd')}
              disableSelectionOnClick
              onSortModelChange={handleOnColumnSort}
              sortModel={tableSortModel}
              checkboxSelection={false}
              isGroupExpandedByDefault={(params) => {
                return true;
              }}
              paginationMode='client'
              editMode='cell'
              sortingMode='client'
              columnBuffer={2}
              columnThreshold={2}
              autoHeight={false}
              sx={insightViewStyle}
              filterMode='client'
              hideFooter
              components={{
                Toolbar: null,
                Pagination: null,
              }}
              experimentalFeatures={{ columnGrouping: true }}
            />
          </Stack>
        )}

        {isDataError && <ErrorBoxComponent error={dataError} />}
      </AnimatedPage>
    </TenantHomeDashboardNavigationBar>
  );
}
